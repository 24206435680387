import { useEffect, useState } from "react";
import useAxiosPost, { authTokenString } from "../../hooks/useAxiosPost";
import { Col, Row, Form, Input, DatePicker, Upload, Button } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import dayjs from "dayjs";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { calculateNextExpiryDate } from "../../helpers/date";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { CameraOutlined } from "@ant-design/icons";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { getBaseApiUrl } from "../../helpers/baseUrl";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const CreateEndUserComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateNewEndUserApiProp>("endUsers/createNewEndUser", true);

  const dateFormat = "YYYY-MM-DD";
  const defaultDate = moment().add(1, "days").startOf("day").format(dateFormat);
  const [form] = Form.useForm();
  const [selectedPackage, setSelectedPackage] = useState<SinglePackage | null>(
    null
  );
  const [packageValue, setPackageValue] = useState();
  const [packages, setPackages] = useState<SinglePackage[]>([]);
  const [packageOptions, setPackageOptions] = useState<MeshBillSelectOptions[]>(
    []
  );

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListBack, setFileListBack] = useState<UploadFile[]>([]);
  const handleChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
  };
  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    setFileList(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };
  const handleRemove = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };
  const handleRemoveBack = (file: UploadFile) => {
    setFileListBack((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
  };
  const handleBeforeUploadBack = (file: RcFile, fileList: RcFile[]) => {
    setFileListBack(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };
  const handleChangeBack = (info: UploadChangeParam) => {
    setFileListBack(info.fileList);
  };
  const [getPackagesApi, packagesApiResponse] = useAxiosPost<ResponseProp>(
    "packages/getPackagesByOwner"
  );
  useEffect(() => {
    getPackagesApi({});
  }, []);
  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;
      const packageOptions: MeshBillSelectOptions[] = data.map(
        (item: any): MeshBillSelectOptions => {
          return {
            value: item.intPkPackageID,
            label: item.packageName,
          };
        }
      );

      console.log({ data });
      setPackages(data || []);
      setPackageOptions(packageOptions);
    }
  }, [packagesApiResponse]);
  const onFinishFailed = (errorInfo: any) => {};
  const onFinish = (values: any) => {
    if (!selectedPackage) {
      return;
    }
    const {
      firstName,
      lastName,
      address,
      phoneNumber,
      username,
      password,
      amountPaid,
      intPkPackageID,
      expiryDate,
    } = values;
    const expiryDateFormatted = expiryDate
      ? expiryDate.format("YYYY-MM-DD")
      : calculateNextExpiryDate(new Date(), selectedPackage.packageDuration);

    const dataToSend: CreateNewEndUserApiProp = {
      firstName,
      lastName,
      address,
      phoneNumber,
      username,
      password,
      amountPaid: Number(amountPaid),
      intPkPackageID: Number(intPkPackageID),
      expiryDate: expiryDateFormatted,
      voucherNumber: values.voucherNumber,
      nicNumber: values.nicNumber ? values.nicNumber : null,
    };
    postApiData(dataToSend);
  };

  const disabledDate = (currentDate: any) => {
    return currentDate && currentDate <= moment().startOf("day");
  };

  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      const data: any = apiResponse.responseData;
      if (message) {
        if (fileList.length > 0 && fileListBack.length > 0) {
          //let's upload the id card here.
          const intPkEndUserID = data.intPkEndUserID;
          const formData = new FormData();
          formData.append("authTokenString", authTokenString.toString());
          formData.append("intPkEndUserID", intPkEndUserID);
          formData.append("idCardFront", fileList[0].originFileObj as Blob);
          formData.append("idCardBack", fileListBack[0].originFileObj as Blob);
          (async () => {
            try {
              const response = await fetch(
                `${getBaseApiUrl()}/endUsers/updateEndUserIdCardDetails`,
                {
                  method: "POST",
                  body: formData,
                  // Note: When using FormData, do NOT set the Content-Type header
                  // The browser will set it automatically, including the boundary parameter
                }
              );

              if (response.ok) {
                const responseData = await response.json();
                openNotification(
                  "success",
                  "Success",
                  responseData?.message || "User Created Successfully"
                );
                setTimeout(() => {
                  navigate(`/`);
                }, 3000);
              } else {
                const responseData = await response.json();

                openNotification("success", "Success", message);

                openNotification(
                  "success",
                  "Success",
                  `User Created But some error while uploading id card`
                );
              }
            } catch (error) {
              openNotification(
                "success",
                "Success",
                `User Created But some error while uploading id card`
              );
            }
          })();
        } else {
          openNotification("success", "Success", message);
          setTimeout(() => {
            navigate(`/pppoe-users`);
          }, 2500);
        }
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  return (
    <>
      {contextHolder}
      <Row className="full-width-row">
        <Form
          name="createPPoEUserForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={true}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Doe"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Second */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone Number",
                  },
                  {
                    pattern: /^0\d{10}$/,
                    message:
                      "Phone number must be 11 digits long and start with '0'",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Main Street house No.10..."
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Third */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter UserName",
                  },
                  {
                    pattern: /^[a-z][a-z0-9_]*$/,
                    message:
                      "Username must start with a lowercase letter and contain only lowercase letters, numbers, and underscores",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="john123"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter Password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="v3ryStrongP@ssw0rD"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fourth */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Select Package</span>
                }
                name="intPkPackageID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Package",
                  },
                ]}
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  value={packageValue}
                  onChange={(e) => {
                    const selectedPackage = packages.find(
                      (item) => item.intPkPackageID === Number(e)
                    );
                    setPackageValue(e);
                    if (!selectedPackage) {
                      return;
                    }
                    const nextExpiryDate = calculateNextExpiryDate(
                      new Date(),
                      selectedPackage?.packageDuration
                    );
                    const defaultDate =
                      moment(nextExpiryDate).format(dateFormat);
                    console.log({ defaultDate });
                    form.setFieldsValue({
                      expiryDate: dayjs(defaultDate, "YYYY-MM-DD"),
                      amountPaid: selectedPackage?.salePrice,
                    });
                    if (selectedPackage) {
                      setSelectedPackage(selectedPackage);
                    } else {
                      setSelectedPackage(null);
                    }
                  }}
                  options={packageOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Amount Paid</span>
                }
                name="amountPaid"
                rules={[
                  {
                    required: true,
                    message: "Please enter Amount Paid",
                  },
                  {
                    validator: (_, value) =>
                      value >= 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Invalid Amount")),
                  },
                ]}
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="100"
                />
              </Form.Item>
            </Col>
            {selectedPackage !== null && (
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Expiry Date</span>
                  }
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Expiry Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%", height: "48px" }}
                    format={dateFormat}
                    defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                    disabled={
                      Number(selectedPackage?.isVariableRechargeAllowed) === 0
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">NIC Number</span>
                }
                name="nicNumber"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="12345678921345"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Voucher Number</span>
                }
                name="voucherNumber"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="ABCDEFG21"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    ID Card (Front)
                  </span>
                }
                name="idCardFront"
              >
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  onChange={handleChange}
                  fileList={fileList}
                >
                  <Button icon={<CameraOutlined />}>Upload Pic</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">ID Card (Back)</span>
                }
                name="idBack"
              >
                <Upload
                  onRemove={handleRemoveBack}
                  beforeUpload={handleBeforeUploadBack}
                  onChange={handleChangeBack}
                  fileList={fileListBack}
                >
                  <Button icon={<CameraOutlined />}>Upload Pic</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Create New End User"
                  disabled={apiLoading}
                  icon={<FiPlusCircle />}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateEndUserComponent;
