import { useEffect, useState } from "react";
import { Row, Col, Card, Form, Switch, Input } from "antd";
import "./../../styles/staff/profile.css";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import MeshBillButton from "../Items/Button/Index";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillSelect from "../Items/Select/Index";

const CompanyGlobalSettingsComponent = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [setting, setSetting] = useState<GlobalSetting | null>(null);
  const [checked, setChecked] = useState(false);
  const [
    postDealerGracePeriodApi,
    dealerGracePeriodApiResponse,
    dealerGracePeriodApiError,
    dealerGracePeriodApiLoading,
    dealerGracePeriodApiMessage,
    dealerGracePeriodApiErrorCount,
  ] = useAxiosPost<CompanyGracePeriodStatusUpdateApi>(
    "globalSettings/updateCompanyGracePeriodStatus"
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("globalSettings/getCompanyGlobalSettings");
  useEffect(() => {
    if (dealerGracePeriodApiResponse !== null) {
      const { message } = dealerGracePeriodApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        postApiData({});
      }
    }
  }, [dealerGracePeriodApiResponse]);

  useEffect(() => {
    if (
      dealerGracePeriodApiError !== null &&
      dealerGracePeriodApiErrorCount !== 0
    ) {
      openNotification(`error`, "Operation Failed", dealerGracePeriodApiError);
    }
  }, [dealerGracePeriodApiError, dealerGracePeriodApiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setSetting(data || null);
      setChecked(Boolean(data.isGracePeriodAllowedInCompany));
      if (Number(data.gracePeriodDays) > 0) {
        form.setFieldsValue({ gracePeriodDays: data.gracePeriodDays });
      }
      if (data.gracePeriodSpeedUp) {
        form.setFieldsValue({ graceSpeed: data.gracePeriodSpeedUp });
      }
      if (data.gracePeriodSpeedAbbreviation) {
        form.setFieldsValue({
          gracePeriodSpeedAbbreviation: data.gracePeriodSpeedAbbreviation,
        });
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);

  const onFinish = (values: any) => {
    console.log("values:", values);
    const { graceSpeed, gracePeriodSpeedAbbreviation, gracePeriodDays } =
      values;
    const data: CompanyGracePeriodStatusUpdateApi = {
      isGracePeriodAllowedInCompany: checked === true ? 1 : 0,
      speedDown: checked === true && graceSpeed ? graceSpeed : null,
      speedUp: checked === true && graceSpeed ? graceSpeed : null,
      gracePeriodSpeedAbbreviation:
        checked === true && gracePeriodSpeedAbbreviation
          ? gracePeriodSpeedAbbreviation
          : null,
      gracePeriodDays:
        checked === true && Number(gracePeriodDays) > 0 ? gracePeriodDays : 0,
    };
    postDealerGracePeriodApi(data);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleSwitchChange = (checked: boolean) => {
    setChecked(checked);
  };
  if (apiLoading || dealerGracePeriodApiLoading) {
    return (
      <>
        {contextHolder}
        <TailSpinLoader />
      </>
    );
  }

  return (
    <>
      {contextHolder}
      {setting !== null && (
        <Row gutter={16} className="full-width-row">
          <Col offset={6} span={12}>
            <Card title="Grace Period" className="global-settings-card">
              <Form
                style={{ width: "100%", marginTop: "50px" }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="updateGracePeriodGlobalSettingsForm"
                form={form}
              >
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Allow Grace Period in Company
                    </span>
                  }
                  name="isGracePeriodAllowedInCompany"
                >
                  <Switch checked={checked} onChange={handleSwitchChange} />
                </Form.Item>
                {checked && (
                  <>
                    <Form.Item
                      label={
                        <span className="create-user-form-label">
                          Grace Period Days
                        </span>
                      }
                      name="gracePeriodDays"
                      rules={[
                        {
                          required: true,
                          message: "Please select grace period days",
                        },
                      ]}
                    >
                      <MeshBillSelect
                        size="large"
                        height="45px"
                        options={[
                          {
                            label: "1 Day",
                            value: 1,
                          },
                          { label: "2 Days", value: 2 },
                          { label: "3 Days", value: 3 },
                          { label: "4 Days", value: 4 },
                          { label: "5 Days", value: 5 },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className="create-user-form-label">Speed</span>
                      }
                      name="graceSpeed"
                    >
                      <Input
                        className="user-input-general-height"
                        placeholder="5"
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className="create-user-form-label">
                          Speed Unit
                        </span>
                      }
                      name="gracePeriodSpeedAbbreviation"
                    >
                      <MeshBillSelect
                        size="large"
                        height="45px"
                        options={[
                          { label: "Kb", value: "K" },
                          { label: "Mb", value: "M" },
                          { label: "Gb", value: "G" },
                        ]}
                      />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <MeshBillButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {}}
                    htmlType="submit"
                    text="Update Grace Period Settings"
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
export default CompanyGlobalSettingsComponent;
