import { useEffect, useState } from "react";
import { Col, Row, Form, Input, DatePicker } from "antd";
import { ButtonProps } from "../../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import moment from "moment";
import dayjs from "dayjs";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import UserCreditHistoryTable from "../../Tables/UserCreditHistoryTable";

const ViewUserCreditHistoryComponent: React.FC<EndUserProfileComponent> = ({
  intPkEndUserID,
  selectedDealer = null,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleEndUserProfileApiProp>(
    "endUsers/getCreditNotesByEndUser"
  );
  const [creditNotes, setCreditNotes] = useState<CreditNote[]>([]);

  useEffect(() => {
    postApiData({ intPkEndUserID, page: 1, selectedDealer: selectedDealer });
  }, []);

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setCreditNotes(data || []);
    }
  }, [apiResponse]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      postApiData({ intPkEndUserID });
    }
  };
  return (
    <>
      {contextHolder}

      <Col span={24} style={{ marginTop: "20px" }}>
        {" "}
      </Col>
      <UserCreditHistoryTable isLoading={apiLoading} notes={creditNotes} />
    </>
  );
};
export default ViewUserCreditHistoryComponent;
