import React, { useState } from "react";
import { Col, Dropdown, Row, Switch, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import { getUserType } from "../../helpers/userDetails";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ViewPackagesTable: React.FC<PackagesTableProp> = ({
  packages,
  isLoading,
  handleUpdatePriceModal,
  handleUpdateDetailsModal,
  handleUpdatePackageGracePeriodModal,
  // handleUpdatePackageGracePeriod,
}) => {
  const role = getUserType();
  const [activeRow, setActiveRow] = useState<SinglePackage | null>(null);

  const packageOptions: MenuProps["items"] = [
    {
      key: "UPDATE_PRICE",
      label: (
        <Typography className="general-label-class">Update Price</Typography>
      ),
    },

    {
      key: "UPDATE_DETAILS",
      label: (
        <Typography className="general-label-class">Update Details</Typography>
      ),
    },
    {
      key: "UPDATE_GRACE_PERIOD",
      label: (
        <Typography className="general-label-class">
          Update Grace Period
        </Typography>
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    switch (e.key) {
      case "UPDATE_PRICE":
        handleUpdatePriceModal(activeRow);
        break;
      case "UPDATE_DETAILS":
        handleUpdateDetailsModal(activeRow);
        break;
      case "UPDATE_GRACE_PERIOD":
        handleUpdatePackageGracePeriodModal(activeRow);
        break;
    }
  };

  const navigate = useNavigate();
  const columns: TableProps<SinglePackage>["columns"] = [
    {
      title: "Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Sale Price",
      dataIndex: "salePrice",
      key: "salePrice",
    },
    {
      title: "Duration",
      dataIndex: "packageDuration",
      key: "packageDuration",
    },
    {
      title: "Pool",
      render: (row) => <span>{row.poolName ? row.poolName : "-"}</span>,
    },
    {
      title: "Profile",
      render: (row) => <span>{row.profileName}</span>,
    },
    {
      title: "Speed",
      render: (row) => (
        <span>
          {row.speedUp && row.speedDown && row.speedAbbreviation
            ? `${row.speedUp}/${row.speedDown} ${row.speedAbbreviation}`
            : "-"}
        </span>
      ),
    },
    {
      title: "Variable Recharge?",
      render: (row) => (
        <span>
          {Number(row.isVariableRechargeAllowed) === 1 ? `YES` : "NO"}
        </span>
      ),
    },

    {
      title: "Created",
      dataIndex: "packagePriceCreated",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },
  ];
  if (role === "ADMIN") {
    columns.push(
      {
        title: "Grace Period",
        render: (row) => (
          <span>{row.isGracePeriodAllowed === 1 ? "Yes" : "No"}</span>
        ),
      },
      {
        title: "Grace Period Speed",
        render: (row) => (
          <span>
            {row.isGracePeriodAllowed === 1
              ? row.gracePeriodSpeedUp &&
                row.gracePeriodSpeedDown &&
                row.gracePeriodSpeedAbbreviation &&
                row.gracePeriodSpeedUp +
                  "/" +
                  row.gracePeriodSpeedDown +
                  " " +
                  row.gracePeriodSpeedAbbreviation
              : "---"}
          </span>
        ),
      },
      {
        title: "Grace Period Days",
        render: (row) => (
          <span>
            {row.isGracePeriodAllowed === 1
              ? row.gracePeriodDays + " Days"
              : "---"}
          </span>
        ),
      },
      {
        title: "Action",
        render: (row) => (
          <Dropdown
            menu={{
              items: packageOptions,
              onClick: handleMenuClick,
            }}
            placement="bottomRight"
          >
            <MenuOutlined
              onMouseEnter={(e) => {
                setActiveRow(row);
              }}
              className="cursor-pointer"
            />
          </Dropdown>
        ),
      }
    );
  }

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">Packages</Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={packages}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default ViewPackagesTable;
