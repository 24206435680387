import React, { useEffect, useState } from "react";
import { Checkbox, Col, Input, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";

import "./../../styles/tables.css";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillSelect from "../Items/Select/Index";

const CreatePackagesTable: React.FC<CreatePackagesTableProp> = ({
  packages,
  isLoading,
  handleUploadPackages,
  isUploading,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [data, setData] = useState<CreateBulkPackageRecord[]>([]);
  useEffect(() => {
    setData(packages);
  }, [packages]);
  const handleInputChange = (value: string, key: string, index: number) => {
    console.log({ value, key, index });
    const newData = [...data];
    newData[index] = {
      ...newData[index],
      [key]: value,
    };
    console.log({ newData });
    setData(newData);
  };
  //   packageDuration
  const columns: TableProps<CreateBulkPackageRecord>["columns"] = [
    {
      title: "Packages",
      render: (row, _, index) => <>{row.packageName}</>,
    },
    {
      title: "Profile Name",
      render: (row: CreateBulkPackageRecord, _, index) => (
        <Input
          disabled={row.isAlreadyCreated}
          value={row.profileName || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "profileName", index)
          }
        />
      ),
    },

    {
      title: "Speed",
      render: (row: CreateBulkPackageRecord, _, index) => (
        <Input
          disabled={row.isAlreadyCreated}
          value={row.speedUnit || ""}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "speedUnit", index)
          }
        />
      ),
    },
    {
      title: "Speed Unit",
      render: (row: CreateBulkPackageRecord, _, index) => (
        <MeshBillSelect
          disabled={row.isAlreadyCreated}
          value={row.speedAbbreviation}
          onChange={(e) => {
            handleInputChange(e, "speedAbbreviation", index);
          }}
          size="large"
          height="45px"
          options={[
            { label: "Kb", value: "K" },
            { label: "Mb", value: "M" },
            { label: "Gb", value: "G" },
          ]}
        />
      ),
    },
    {
      title: "Sale Price",
      render: (row: CreateBulkPackageRecord, _, index) => (
        <Input
          disabled={row.isAlreadyCreated}
          value={row.salePrice || ""}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "salePrice", index)
          }
        />
      ),
    },
    {
      title: "Package Duration",
      render: (row: CreateBulkPackageRecord, _, index) => (
        <MeshBillSelect
          value={row.packageDuration}
          disabled={row.isAlreadyCreated}
          onChange={(e) => {
            handleInputChange(e, "packageDuration", index);
          }}
          size="large"
          height="45px"
          options={[
            { value: "MONTHLY", label: "Monthly" },
            { value: "THIRTY_DAYS", label: "Thirty Days" },
          ]}
        />
      ),
    },
    {
      title: "Create Queue",
      render: (row: CreateBulkPackageRecord, _, index) => (
        <Checkbox
          disabled={row.isAlreadyCreated}
          checked={Boolean(row.isCreateDynamicQueue)}
          onChange={(e: any) =>
            handleInputChange(e.target.checked, "isCreateDynamicQueue", index)
          }
        >
          <span className="create-user-form-label">Create Dynamic Que</span>
        </Checkbox>
      ),
    },
    {
      title: "Variable Recharge?",
      render: (row, _, index) => (
        <Checkbox
          disabled={row.isAlreadyCreated}
          checked={Boolean(row.isVariableRechargeAllowed)}
          onChange={(e: any) =>
            handleInputChange(
              e.target.checked,
              "isVariableRechargeAllowed",
              index
            )
          }
        >
          <span className="create-user-form-label">
            Variable Recharge Allowed?
          </span>
        </Checkbox>
      ),
    },
  ];
  const rowClassName = (record: CreateBulkPackageRecord) => {
    return record.isAlreadyCreated ? "disabled-user" : "";
  };

  const validateData = (data: CreateBulkPackageRecord[]) => {
    return data.filter((row) => {
      if (row.isAlreadyCreated === true) return true;
      return (
        row.salePrice &&
        Number(row.salePrice) > 0 &&
        row.speedUnit &&
        Number(row.speedUnit) > 0
      );
    });
  };

  const handleUpload = () => {
    const validData = validateData(data);
    console.log({ validData, data });
    if (validData.length < data.length) {
      // Notify user about incomplete rows
      openNotification(
        `error`,
        "Operation Failed",
        "Please fill in all required fields for each package."
      );
    } else {
      handleUploadPackages(
        validData.filter((item) => item.isAlreadyCreated !== true)
      );
    }
  };

  return (
    <div className="general-table-container">
      {contextHolder}
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">Packages</Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={data}
              pagination={false}
              rowClassName={rowClassName}
              scroll={{ x: 1300, y: 500 }}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
              paddingRight: "30px",
            }}
            className="mt-20 mb-20"
          >
            {data.length > 0 && (
              <MeshBillButton
                htmlType="button"
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.STANDARD}
                textColor={ButtonProps.color.STANDARD}
                onClick={handleUpload}
                text="Create Packages"
                disabled={isUploading}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default CreatePackagesTable;
