import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import UpdatePackageSalePriceModalForDealer from "../Modals/UpdatePackageSalePriceModalForDealer";
import ViewPackagesTableForDealer from "../Tables/ViewPackagesTableForDealer";

const ViewDealerPackagesComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("dealers/getPackagesAssignedToMe");
  const [packages, setPackages] = useState<DealerPackage[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showUpdatePrice, setShowUpdatePrice] = useState<boolean>(false);
  const [showUpdateDetails, setShowUpdateDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<DealerPackage | null>(null);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setPackages(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    setShowUpdatePrice(false);
    setShowUpdateDetails(false);
    setActiveRow(null);
    if (isReload) {
      postApiData({});
    }
  };
  const handleUpdatePriceModal = (activePackage: DealerPackage) => {
    setActiveRow(activePackage);
    setShowUpdatePrice(true);
  };
  const handleUpdateDetailsModal = (activePackage: DealerPackage) => {
    setActiveRow(activePackage);
    setShowUpdateDetails(true);
  };
  return (
    <>
      {showUpdatePrice === true && activeRow !== null && (
        <UpdatePackageSalePriceModalForDealer
          show={showUpdatePrice}
          handleClose={handleClose}
          activePackage={activeRow}
        />
      )}

      <Row className="full-width-row">
        <Col span={24}>
          <ViewPackagesTableForDealer
            handleUpdatePriceModal={handleUpdatePriceModal}
            isLoading={apiLoading}
            packages={packages}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewDealerPackagesComponent;
