import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox, Switch } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";

const UpdatePackageGracePeriodModal: React.FC<UpdatePackageModalProp> = ({
  show,
  handleClose,
  activePackage,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<PackageGracePeriodStatusUpdate>(
    "globalSettings/updatePackageGracePeriodSettings",
    true
  );
  const [checked, setChecked] = useState<boolean>(
    activePackage.isGracePeriodAllowed === 1 ? true : false
  );

  const handleSwitchChange = (checked: boolean) => {
    setChecked(checked);
  };

  const onFinish = (values: any) => {
    const { gracePeriodDays, graceSpeed, gracePeriodSpeedAbbreviation } =
      values;
    const dataToSend: PackageGracePeriodStatusUpdate = {
      isGracePeriodAllowed: checked === true ? 1 : 0,
      speedDown: checked === true && graceSpeed ? graceSpeed : null,
      speedUp: checked === true && graceSpeed ? graceSpeed : null,
      gracePeriodSpeedAbbreviation:
        checked === true && gracePeriodSpeedAbbreviation
          ? gracePeriodSpeedAbbreviation
          : null,
      gracePeriodDays:
        checked === true && Number(gracePeriodDays) > 0 ? gracePeriodDays : 0,
      intPkPackageID: activePackage.intPkPackageID,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <MeshBillModal
        title="Update Package Grace Period Details"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={true}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updatePackageGracePeriodDetailsDetails"
              initialValues={{
                gracePeriodDays:
                  activePackage.gracePeriodDays > 0
                    ? Number(activePackage.gracePeriodDays)
                    : undefined,
                graceSpeed: activePackage.gracePeriodSpeedUp,
                gracePeriodSpeedAbbreviation:
                  activePackage.gracePeriodSpeedAbbreviation,
              }}
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Allow Grace Period in Package
                  </span>
                }
                name="isGracePeriodAllowed"
              >
                <Switch checked={checked} onChange={handleSwitchChange} />
              </Form.Item>
              {checked === true && (
                <>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Grace Period Days
                      </span>
                    }
                    name="gracePeriodDays"
                  >
                    <MeshBillSelect
                      size="large"
                      height="45px"
                      options={[
                        {
                          label: "1 Day",
                          value: 1,
                        },
                        { label: "2 Days", value: 2 },
                        { label: "3 Days", value: 3 },
                        { label: "4 Days", value: 4 },
                        { label: "5 Days", value: 5 },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">Speed</span>
                    }
                    name="graceSpeed"
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="5"
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <span className="create-user-form-label">Speed Unit</span>
                    }
                    name="gracePeriodSpeedAbbreviation"
                  >
                    <MeshBillSelect
                      size="large"
                      height="45px"
                      options={[
                        { label: "Kb", value: "K" },
                        { label: "Mb", value: "M" },
                        { label: "Gb", value: "G" },
                      ]}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Update Package Details"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default UpdatePackageGracePeriodModal;
