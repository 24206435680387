import React, { useContext, useEffect, useState } from "react";
import { Typography, Dropdown } from "antd";
import ArrowDown from "./../../assets/svgs/ArrowDown.svg";
import ProfileIconBlue from "./../../assets/svgs/ProfileIconBlue.svg";
import KeyIcon from "./../../assets/svgs/KeyIcon.svg";
import LogoutIcon from "./../../assets/svgs/LogoutIcon.svg";
import CompanySettingsIcon from "./../../assets/svgs/CompanySettingsIcon.svg";

import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { getUserType, handleLogout } from "../../helpers/userDetails";
import useAxiosPost from "../../hooks/useAxiosPost";
import BalanceContext from "../../context/BalanceContext";

const TopNavBar: React.FC<any> = ({ getMeCompanyName }) => {
  const { value, setValue } = useContext(BalanceContext);

  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyProfile");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      getMeCompanyName(data.companyName);
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (value > 0) {
      postApiData({});
    }
  }, [value]);
  const navigate = useNavigate();
  const connectionOptionItems: MenuProps["items"] = [
    {
      key: "PROFILE",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={ProfileIconBlue} />
          User Profile
        </Typography>
      ),
    },
    {
      key: "CHANGE_PASSWORD",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={KeyIcon} />
          Change Password
        </Typography>
      ),
    },
    {
      key: "LOGOUT",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={LogoutIcon} />
          Logout
        </Typography>
      ),
    },
  ];
  if (getUserType() === "ADMIN") {
    connectionOptionItems.splice(2, 0, {
      key: "COMPANY_SETTINGS",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={CompanySettingsIcon} />
          Company Settings
        </Typography>
      ),
    });
  }

  const handleMenuClick = (e: any) => {
    if (e.key === "PROFILE") {
      navigate("/auth/profile");
    } else if (e.key === "COMPANY_SETTINGS") {
      navigate(`/auth/company-settings`);
    } else if (e.key === "CHANGE_PASSWORD") {
      navigate(`/auth/update-password`);
    } else if (e.key === "LOGOUT") {
      handleLogout();
    } else if (e.key === "LOGOUT") {
      handleLogout();
    }
  };
  const showName = (staffUserName: string, role: string) => {
    return `${staffUserName} (${role})`;
  };
  return (
    <>
      <div className="top-nav-bar-items-container">
        {/* {navItems.map((item: NavItem) => {
          return (
            <div key={Math.random()} className="top-nav-bar-item">
              {<img src={item.icon} />}
            </div>
          );
        })} */}

        {/* <Select
          className="top-nav-select-control"
          size={"middle"}
          defaultValue="Hamza Arshad"
          //   onChange={handleChange}
          style={{ width: 200 }}
          options={options}
        /> */}
        <Dropdown
          menu={{
            items: connectionOptionItems,

            onClick: handleMenuClick,
          }}
          placement="bottomLeft"
        >
          <div className="user-profile-options-container">
            <div className="user-profile-container-with-profile-icon">
              <img src={ProfileIconBlue} />{" "}
              <Typography className="user-name-options-label">
                {staff && showName(staff.staffUserName, staff.staffRole)}
              </Typography>{" "}
            </div>
            <img className="profile-arrow-down" src={ArrowDown} />
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default TopNavBar;
