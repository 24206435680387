import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";

// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));

const CreateNewRouterModalComponent: React.FC<CreateNewRouterModalProps> = ({
  show,
  handleClose,
  intPkCompanyID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [selectedProbe, setSelectedProbe] = useState("");
  const [checked, setChecked] = useState<boolean>(false);
  const [coaEnabled, setCOAEnabled] = useState<boolean>(false);
  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };
  const handleCOAUpdate = (e: any) => {
    setCOAEnabled(e.target.checked);
  };
  const [probes, setProbes] = useState<MeshBillSelectOptions[]>([]);
  const [authPorts, setAuthPorts] = useState<MeshBillSelectOptions[]>([]);
  const [acctPorts, setAcctPorts] = useState<MeshBillSelectOptions[]>([]);
  const [mikronocProbes, setMikronocProbes] = useState<MeshBillSelectOptions[]>(
    []
  );
  const [getPortsApi, portsApiResponse] =
    useAxiosPost<GetChildProbesByParentApiProp>(
      "radiusProbes/getChildProbesByRadiusProbe"
    );
  const [radiusProbesApi, radiusProbesResponse] = useAxiosPost<ResponseProp>(
    "radiusProbes/getRadiusProbes"
  );
  const [mikroNocApi, mikroNoCApiResponse] = useAxiosPost<ResponseProp>(
    "routers/getMikronocProbes"
  );

  useEffect(() => {
    if (mikroNoCApiResponse !== null) {
      const data: any = mikroNoCApiResponse.responseData;
      const probes = data.probes || [];
      const mikronocProbes = probes.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkMikroNOCProbeID,
          label: item.mikroNocProbeIP,
        };
      });
      setMikronocProbes(mikronocProbes);
    }
  }, [mikroNoCApiResponse]);
  useEffect(() => {
    if (radiusProbesResponse !== null) {
      const data: any = radiusProbesResponse.responseData;
      const areas = data.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkMeshBillRadiusProbeID,
          label: item.radiusProbeIP,
        };
      });

      setProbes(areas);
    }
  }, [radiusProbesResponse]);
  useEffect(() => {
    if (portsApiResponse !== null) {
      const data: any = portsApiResponse.responseData;
      const _accPorts: MeshBillSelectOptions[] = [];
      const _authPorts: MeshBillSelectOptions[] = [];
      for (const item of data) {
        if (item.portType === "AUTH") {
          _authPorts.push({
            label: item.port,
            value: item.intPkMeshBillRadiusProbeChildID,
          });
        } else {
          _accPorts.push({
            label: item.port,
            value: item.intPkMeshBillRadiusProbeChildID,
          });
        }
      }
      setAuthPorts(_authPorts);
      setAcctPorts(_accPorts);
    }
  }, [portsApiResponse]);
  useEffect(() => {
    radiusProbesApi({});
    mikroNocApi({});
  }, []);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateNewNasDeviceApiProp>(
    "routers/createNewNasDevice",
    true
  );

  const onFinish = (values: any) => {
    const {
      nasDeviceApiPassword,
      nasDeviceApiPort,
      nasDeviceApiUserName,
      coaPort,
    } = values;
    const mNocProbe = mikronocProbes.find(
      (item) => Number(item.value) === Number(values.intPkMikroNOCProbeID)
    );
    if (!mNocProbe) {
      openNotification("error", "Error", `Error While Selecting Mikronoc`);
      return;
    }
    const dataToSend: CreateNewNasDeviceApiProp = {
      nasDeviceApiPassword: null,
      nasDeviceApiPort: null,
      nasDeviceApiUserName: null,
      nasDeviceIP: values.nasDeviceIP,
      nasDeviceName: values.nasDeviceName,
      nasDeviceType: values.nasDeviceType,
      intPkCompanyID,
      childProbeAccountingID: Number(values.childProbeAccountingID),
      childProbeAuthID: Number(values.childProbeAuthID),
      coaPort: null,
      isApiEnabled: checked,
      isCoaEnabled: coaEnabled,
      intPkMikroNOCProbeID: `${values.intPkMikroNOCProbeID}`,
      mikroNocProbeIP: mNocProbe.label,
    };
    if (checked) {
      if (!nasDeviceApiPassword || !nasDeviceApiPort || !nasDeviceApiUserName) {
        openNotification(
          "error",
          "Error",
          `You Enabled Api But did not provided the details `
        );
        return;
      }
      dataToSend.nasDeviceApiPassword = nasDeviceApiPassword;
      dataToSend.nasDeviceApiPort = nasDeviceApiPort;
      dataToSend.nasDeviceApiUserName = nasDeviceApiUserName;
    }

    if (coaEnabled) {
      if (!coaPort) {
        openNotification(
          "error",
          "Error",
          `You Enabled COA But did not provided the details  `
        );
        return;
      }
      dataToSend.coaPort = coaPort;
    }
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const validateIPAddress = async (_: any, value: any) => {
    // Regular expression for validating IP address (IPv4)
    const ipRegex =
      /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!value) {
      return Promise.reject("Please enter an IP address");
    }

    if (!ipRegex.test(value)) {
      return Promise.reject("Please enter a valid IP address");
    }

    return Promise.resolve();
  };
  const handleRadiusProbeChange = (value: string) => {
    form.setFieldsValue({
      childProbeAccountingID: undefined,
      childProbeAuthID: undefined,
    });

    getPortsApi({ intPkMeshBillRadiusProbeID: Number(value) });
  };

  return (
    <>
      <MeshBillModal
        title="Create Router"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={true}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="createNewRouterForm"
              initialValues={{ nasDeviceApiPort: 8728, coaPort: 3799 }}
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Router Name</span>
                }
                name="nasDeviceName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Router Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Lahore Area Router"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Router IP Type</span>
                }
                name="nasDeviceType"
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  options={[
                    {
                      label: "Static IP",
                      value: "STATIC_IP",
                    },
                    { label: "Dynamic IP", value: "DYNAMIC_IP" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Router IP</span>
                }
                name="nasDeviceIP"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Router IP",
                  },
                  { validator: validateIPAddress },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="123.123.123.132"
                />
              </Form.Item>
              <Form.Item name="isVariableRechargeAllowed">
                <Checkbox checked={checked} onChange={onChange}>
                  <span className="create-user-form-label">Enable API?</span>
                </Checkbox>
              </Form.Item>
              {checked === true && (
                <>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Router API PORT
                      </span>
                    }
                    name="nasDeviceApiPort"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Router API PORT",
                      },
                      {
                        validator: (_, value) =>
                          value >= 0
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid PORT")),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      className="user-input-general-height"
                      placeholder="123"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Router API UserName
                      </span>
                    }
                    name="nasDeviceApiUserName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Router Username",
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="router1_lahore1"
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Router API Password
                      </span>
                    }
                    name="nasDeviceApiPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Router Password",
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="router1_lahore1"
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item name="isVariableRechargeAllowed">
                <Checkbox checked={coaEnabled} onChange={handleCOAUpdate}>
                  <span className="create-user-form-label">Enable COA?</span>
                </Checkbox>
              </Form.Item>
              {coaEnabled === true && (
                <Form.Item
                  label={
                    <span className="create-user-form-label">COA PORT</span>
                  }
                  name="coaPort"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter COA PORT",
                    },
                    {
                      validator: (_, value) =>
                        value >= 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Invalid COA PORT")),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="user-input-general-height"
                    placeholder="123"
                  />
                </Form.Item>
              )}

              <Form.Item
                label={
                  <span className="create-user-form-label">
                    {" "}
                    Choose MikroNoc Probe
                  </span>
                }
                name="intPkMikroNOCProbeID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Probe",
                  },
                ]}
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  options={mikronocProbes}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Radius Probe</span>
                }
                name="intPkMeshBillRadiusProbeID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Probe",
                  },
                ]}
              >
                <MeshBillSelect
                  value={selectedProbe}
                  onChange={handleRadiusProbeChange}
                  size="large"
                  height="45px"
                  options={probes}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Choose Auth Port
                  </span>
                }
                name="childProbeAuthID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Port",
                  },
                ]}
              >
                <MeshBillSelect
                  // value={selectedProbe}
                  // onChange={handleRadiusProbeChange}
                  size="large"
                  height="45px"
                  options={authPorts}
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Choose Accounting Port
                  </span>
                }
                name="childProbeAccountingID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Port",
                  },
                ]}
              >
                <MeshBillSelect
                  // value={selectedProbe}
                  // onChange={handleRadiusProbeChange}
                  size="large"
                  height="45px"
                  options={acctPorts}
                />
              </Form.Item>

              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Create New Router"
                  disabled={apiLoading}
                  icon={<FiPlusCircle />}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default CreateNewRouterModalComponent;
