import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const UpdatePackageSalePriceModalForDealer: React.FC<
  UpdatePackageModalForDealerProp
> = ({ show, handleClose, activePackage }) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdatePackagePriceApiProp>(
    `dealers/${
      activePackage.endUserPriceDetails
        ? "updatePriceForDealerPackage"
        : "createSalePriceForNewlyAssignedPackage"
    }`,
    true
  );
  const [checked, setChecked] = useState<boolean>(false);
  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const onFinish = (values: any) => {
    const { salePrice } = values;
    if (activePackage.endUserPriceDetails) {
      if (Number(salePrice) === activePackage.endUserPriceDetails.salePrice) {
        openNotification("error", "Error", `Price Change Not Detected`);
        return;
      }
    }
    const dataToSend: UpdatePackagePriceApiProp = {
      salePrice,
      intPkPackageID: activePackage.intPkPackageID,
      intPkPackagePriceID:
        activePackage.endUserPriceDetails !== null
          ? activePackage.endUserPriceDetails.intPkPackagePriceID
          : null,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <MeshBillModal
        title={
          activePackage.endUserPriceDetails ? "Create Price" : "Update Price"
        }
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={true}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updatePackageSalePriceForm"
              initialValues={{
                salePrice: activePackage.endUserPriceDetails
                  ? activePackage.endUserPriceDetails.salePrice
                  : 0,
              }}
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Package Price</span>
                }
                name="salePrice"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Package Price",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Invalid Price")),
                  },
                ]}
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="1000"
                />
              </Form.Item>

              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default UpdatePackageSalePriceModalForDealer;
