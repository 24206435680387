import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox, DatePicker } from "antd";
import { ButtonProps, getWalletDays } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";
import moment from "moment";
import dayjs from "dayjs";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const UpdateExpiryDateModal: React.FC<EndUserUpdateModalProps> = ({
  show,
  handleClose,
  endUser,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateEndUserExpiryDateApiProp>(
    "endUsers/updateEndUserExpiryDate",
    true
  );
  const dateFormat = "YYYY-MM-DD";
  const defaultDate =
    new Date() > new Date(endUser.expiryDate)
      ? moment().add(1, "days").startOf("day").format(dateFormat)
      : moment(endUser.expiryDate).startOf("day").format(dateFormat);
  console.log(defaultDate);

  const onFinish = (values: any) => {
    console.log(values.expiryDate.format("YYYY-MM-DD"));
    const dataToSend: UpdateEndUserExpiryDateApiProp = {
      intPkEndUserID: endUser.intPkEndUserID,
      expiryDate: values.expiryDate.format("YYYY-MM-DD"),
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const disabledDate = (currentDate: any) => {
    return currentDate && currentDate <= moment().startOf("day");
  };

  return (
    <>
      <MeshBillModal
        title={`Update Expiry Date`}
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={true}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateEndUserExpiryDateForm"
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Expiry Date</span>
                }
                name="expiryDate"
                rules={[
                  {
                    required: true,
                    message: "Please enter Expiry Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%", height: "48px" }}
                  format={dateFormat}
                  defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default UpdateExpiryDateModal;
