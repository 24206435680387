import React, { useState } from "react";
import { Col, Dropdown, Row, Switch, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import "./../../styles/tables.css";
import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { MenuOutlined } from "@ant-design/icons";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));

const ViewRoutersTable: React.FC<RoutersTableProp> = ({
  routers,
  isLoading,
  role,
  handleRouterStatusChange,
  companyCode,
  companyName,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<Router | null>(null);
  const staffOptions: MenuProps["items"] = [
    {
      key: "VIEW_DETAILS",
      label: (
        <Typography className="general-label-class">View Details</Typography>
      ),
    },
  ];
  const columns: TableProps<Router>["columns"] = [
    {
      title: "Name",
      dataIndex: "nasDeviceName",
      key: "nasDeviceName",
    },
    {
      title: "IP",
      dataIndex: "nasDeviceIP",
      key: "nasDeviceIP",
      render: (text) => (
        <Tag style={{ fontWeight: "bold" }}>{text ? text : "-"} </Tag>
      ),
    },
    {
      title: "Up Time",
      render: (row) => (
        <span>{row.resourceDetails ? row.resourceDetails.uptime : "-"}</span>
      ),
    },
    {
      title: "CPU Load",
      render: (row) => (
        <span>
          {row.resourceDetails ? row.resourceDetails.cpuLoad + "%" : "-"}
        </span>
      ),
    },
    {
      title: "Total CPUs",
      render: (row) => (
        <span>{row.resourceDetails ? row.resourceDetails.cpuCount : "-"}</span>
      ),
    },
    // {
    //   title: "API UserName",
    //   dataIndex: "nasDeviceApiUserName",
    //   key: "nasDeviceApiUserName",
    // },
    // {
    //   title: "API PORT",
    //   dataIndex: "nasDeviceApiPort",
    //   key: "nasDeviceApiPort",
    // },
    // {
    //   title: "Router Token",
    //   render: (row) => (
    //     <CopyToClipboard text={row.routerToken || ""} onCopy={() => {}}>
    //       <MdContentCopy
    //         onClick={(e) => {
    //           e.preventDefault();
    //         }}
    //         className="cursor-pointer "
    //       />
    //     </CopyToClipboard>
    //   ),
    // },

    // {
    //   title: "Radius Secret",
    //   render: (row) => (
    //     <CopyToClipboard text={row.radiusSecret || ""} onCopy={() => {}}>
    //       <MdContentCopy
    //         onClick={(e) => {
    //           e.preventDefault();
    //         }}
    //         className="cursor-pointer "
    //       />
    //     </CopyToClipboard>
    //   ),
    // },

    {
      title: "Authority Reachable",
      render: (row) => (
        <span>
          {row.authorityStatus === null || false ? (
            <Tag color="error">Un Reachable</Tag>
          ) : (
            <Tag color="success">Reachable</Tag>
          )}
        </span>
      ),
    },
    {
      title: "COA Enabled?",
      render: (row) => (
        <span>
          {Number(row.isCoaEnabled) === 1 ? (
            <Tag color="success">Enable</Tag>
          ) : (
            <Tag color="warning">Disabled</Tag>
          )}
        </span>
      ),
    },

    {
      title: "API Reachable?",
      render: (row) => (
        <span>
          {row.apiStatus === null || false ? (
            <Tag color="error">Un Reachable</Tag>
          ) : (
            <Tag color="success">Reachable</Tag>
          )}
        </span>
      ),
    },
    {
      title: "API Enabled?",
      render: (row) => (
        <span>
          {Number(row.isApiEnabled) === 1 ? (
            <Tag color="success">Enable</Tag>
          ) : (
            <Tag color="warning">Disabled</Tag>
          )}
        </span>
      ),
    },
  ];
  columns.push(
    {
      title: "Status",
      render: (row) => (
        <span>
          {Number(row.isNasDevicePreTested) === 1
            ? "Activated"
            : "Not Activated"}
        </span>
      ),
    },
    {
      title: "Created",
      dataIndex: "deviceCreated",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    }
  );
  if (role === "SUPER_ADMIN") {
    columns.push({
      title: "Enable",
      render: (row) => (
        <Switch
          value={Boolean(row.enable)}
          onChange={(e) => {
            handleRouterStatusChange(row.intPkNasDeviceID, row.enable);
          }}
        />
      ),
    });
  }
  columns.push({
    title: "Action",
    render: (row) => (
      <Dropdown
        menu={{
          items: staffOptions,
          onClick: handleMenuClick,
        }}
        placement="bottomRight"
      >
        <MenuOutlined
          onMouseEnter={(e) => {
            setActiveRow(row);
          }}
          className="cursor-pointer"
        />
      </Dropdown>
    ),
  });
  const openDetailsPage = (activeRow: any) => {
    if (role === "SUPER_ADMIN") {
      return navigate(
        `/super-admin/company-routers/router-details/${activeRow.intPkNasDeviceID}/${activeRow.intPkCompanyID}`
      );
    } else {
      return navigate(`/routers/router-details/${activeRow.intPkNasDeviceID}`);
    }
  };
  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }

    switch (e.key) {
      case "VIEW_DETAILS":
        openDetailsPage(activeRow);
        break;
    }
  };

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              Routers &nbsp;
              {companyName && companyCode && (
                <>
                  (Company Name : {companyName} & Company Code: {companyCode})
                </>
              )}
            </Typography>
            <Table
              className="striped-table"
              columns={columns}
              dataSource={routers}
              pagination={false}
              scroll={{ x: 1300, y: 1300 }}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          ></Col>
        </Row>
      )}
    </div>
  );
};
export default ViewRoutersTable;
