import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import "./../../styles/tables.css";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
function getStatusSpan(row: any): JSX.Element {
  if (!row.isDealerCreated) {
    return <Tag color="error">Dealer Not Created</Tag>;
  } else if (row.isAlreadyCreated) {
    return <Tag color="warning">Created</Tag>;
  } else {
    return <Tag color="success">Select</Tag>;
  }
}
const RowRenderer: React.FC<any> = ({ index, style, data }) => {
  let _data = data.data;
  const row = _data[index];
  const handleInputChange = data.handleInputChange;

  return (
    <div style={style}>
      <Row gutter={[16, 16]}>
        <Col span={3}>
          <Checkbox
            disabled={!row.isDealerCreated || row.isAlreadyCreated}
            checked={row.isReadyToCreate}
            onChange={(e: any) => {
              handleInputChange(
                e.target.checked,
                "isReadyToCreate",
                row.username
              );
            }}
          >
            {getStatusSpan(row)}
          </Checkbox>
        </Col>
        <Col span={2}>
          <Typography>{`${row.username}`}</Typography>
        </Col>
        <Col span={2}>
          <Typography>{`${row.firstName}`}</Typography>
        </Col>
        <Col span={2}>
          <Typography>{`${row.lastName}`}</Typography>
        </Col>

        <Col span={2}>
          <span>{`${row.password}`}</span>
        </Col>
        <Col span={2}>
          <span>{`${row.phoneNumber}`}</span>
        </Col>
        <Col span={2}>
          <span>{`${row.address ? row.address.substring(0, 10) : ""}`}</span>
        </Col>
        <Col span={2}>
          <span>{`${row.nicNumber}`}</span>
        </Col>
        <Col span={3}>
          <MeshBillSelect
            disabled={row.isAlreadyCreated || !row.isReadyToCreate}
            size="large"
            options={data.packageOptions}
            value={row.intPkPackageID}
            height="35px"
            onChange={(e) =>
              handleInputChange(e, "intPkPackageID", row.username)
            }
          />
        </Col>
        <Col span={2}>
          <span>{`${row.expiryDateToDisplay}`}</span>
        </Col>
        <Col span={2}>
          <span>{`${row.dealer}`}</span>
        </Col>
      </Row>
    </div>
  );
};

const ImportUsersThroughFileTable: React.FC<
  ImportUsersThroughFileTableProp
> = ({
  users,
  packageOptions,
  isUploading,
  // handleUserSkip,
  handleUploadUsers,
  dealers,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [dealer, setDealer] = useState<string | null>(null);
  const [allDealers, setAllDealers] = useState<BulkDealerForApi[]>([]);
  const [activeRow, setActiveRow] = useState<UsersCsvData | null>(null);
  const [data, setData] = useState<UsersCsvData[]>([]);
  const [actualData, setActualData] = useState<UsersCsvData[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<number>(0);
  const [activeDealer, setActiveDealer] = useState<BulkDealerForApi | null>(
    null
  );
  useEffect(() => {
    setData(users);
    setActualData(users);
    setAllDealers(dealers);
  }, [users, dealers]);

  useEffect(() => {
    setTotalUsers(data.length);
    const selectedCount = data.reduce(
      (acc, user) => (user.isReadyToCreate ? acc + 1 : acc),
      0
    );
    setSelectedUsers(selectedCount);
  }, [data]);

  const handleInputChange = useCallback(
    (value: string, key: string, username: string) => {
      setActualData((prevData) =>
        prevData.map((item) =>
          item.username === username ? { ...item, [key]: value } : item
        )
      );
      setData((prevData) =>
        prevData.map((item) =>
          item.username === username ? { ...item, [key]: value } : item
        )
      );
    },
    []
  );
  const handlePackageChange = (value: string, key: number) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, intPkPackageID: value } : item
    );
    setData(updatedData);
  };

  const validateData = (data: UsersCsvData[]): UsersCsvData[] => {
    return data.filter((row) => {
      if (row.isAlreadyCreated === true) return true;
      if (row.isDealerCreated === false) return true;
      return row.intPkPackageID;
    });
  };
  const handleUpload = () => {
    const validData = validateData(data);
    // console.log({ validData, data });
    if (validData.length < data.length) {
      // Notify user about incomplete rows
      openNotification(
        `error`,
        "Operation Failed",
        "Please fill in all required fields for each selected user."
      );
    } else {
      const dataToUpload = validData.filter(
        (item) => item.isReadyToCreate === true
      );
      if (dataToUpload.length < 1) {
        openNotification(
          `error`,
          "Operation Failed",
          "Please select at least one user."
        );
        return;
      }
      handleUploadUsers(dataToUpload);
    }
  };
  const handleUserSkip = (username: string) => {
    const updatedData = data.filter((user) => user.username !== username);
    setData(updatedData);
  };
  const handleCheckUncheckAll = (isReadyToCreate: boolean) => {
    setAllDealers((prevData) =>
      prevData.map((item) => ({ ...item, isChecked: isReadyToCreate }))
    );
    setData((prevData) =>
      prevData.map((row) =>
        !row.isAlreadyCreated && row.isDealerCreated
          ? { ...row, isReadyToCreate }
          : row
      )
    );
    setActualData((prevData) =>
      prevData.map((row) =>
        !row.isAlreadyCreated && row.isDealerCreated
          ? { ...row, isReadyToCreate }
          : row
      )
    );
  };
  const handleCheckUncheckAllForDealer = (
    isReadyToCreate: boolean,
    dealer: string
  ) => {
    setData((prevData) =>
      prevData.map((row) =>
        !row.isAlreadyCreated && row.isDealerCreated && row.dealer === dealer
          ? { ...row, isReadyToCreate }
          : row
      )
    );
    setActualData((prevData) =>
      prevData.map((row) =>
        !row.isAlreadyCreated && row.isDealerCreated && row.dealer === dealer
          ? { ...row, isReadyToCreate }
          : row
      )
    );
  };
  const handleDealerFilter = (dealer: string) => {
    if (dealer === "-1") {
      setData(actualData);
    } else {
      const filteredData = actualData.filter((item) => item.dealer === dealer);
      setData(filteredData);
    }
  };
  return (
    <div className="general-table-container" style={{ minWidth: "1331px" }}>
      {contextHolder}
      {/* <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 100 }}
          /> */}
      <Row>
        <Col span={24}>
          <div className="import-table-header-container">
            <Typography className="import-table-header">Users</Typography>
          </div>
          <Row gutter={[16, 16]}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
              span={6}
            >
              <Select
                value={dealer}
                onChange={(value) => {
                  setDealer(value);
                  if (value === "-1") {
                    setActiveDealer(null);
                  } else {
                    const found = allDealers.find(
                      (item) => item.staffUserName === value
                    );
                    if (found) {
                      setActiveDealer(found);
                    }
                  }
                  handleDealerFilter(value);
                }}
                placeholder="Select Dealer"
                style={{ width: "100%" }}
              >
                <Select.Option value={"-1"}>All</Select.Option>
                {allDealers.map((item) => {
                  return (
                    <Select.Option value={item.staffUserName}>
                      {item.staffUserName}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row>
            {activeDealer !== null ? (
              <>
                <Col span={5}>
                  <Checkbox
                    value={activeDealer.isChecked}
                    checked={activeDealer.isChecked}
                    onChange={(e) => {
                      setActiveDealer({
                        ...activeDealer,
                        isChecked: e.target.checked,
                      });
                      setAllDealers((prevData) =>
                        prevData.map((item) =>
                          item.staffUserName === activeDealer.staffUserName
                            ? { ...item, isChecked: e.target.checked }
                            : item
                        )
                      );

                      handleCheckUncheckAllForDealer(
                        e.target.checked,
                        activeDealer.staffUserName
                      );
                    }}
                  />{" "}
                  Check All ({activeDealer.staffUserName})
                </Col>
              </>
            ) : (
              <Col span={5}>
                <Checkbox
                  value={checkAll}
                  checked={checkAll}
                  onChange={(e) => {
                    setCheckAll(e.target.checked);
                    handleCheckUncheckAll(e.target.checked);
                  }}
                />{" "}
                Check All
              </Col>
            )}
            <Col span={14}></Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "5",
              }}
              span={5}
            >
              <Typography>Total Users : {totalUsers}</Typography>
              <Typography>Selected Users : {selectedUsers}</Typography>
            </Col>
          </Row>
          {/* <Col span={3}>
            <span>{`${row.expiryDateToDisplay}`}</span>
          </Col>
          <Col span={2}>
            <span>{`${row.dealer}`}</span>
          </Col> */}
          <Row
            className="full-width-row import-custom-titles-container"
            gutter={[16, 16]}
          >
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                Action
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                UserName
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                First Name
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                Last Name
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                Password
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                Phone Number
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                Address
              </Typography>
            </Col>

            <Col span={2}>
              <Typography className="import-custom-titles-label">
                NIC
              </Typography>
            </Col>

            <Col span={3}>
              <Typography className="import-custom-titles-label">
                Package
              </Typography>
            </Col>
            <Col span={3}>
              <Typography className="import-custom-titles-label">
                Expiry
              </Typography>
            </Col>
            <Col span={2}>
              <Typography className="import-custom-titles-label">
                Dealer
              </Typography>
            </Col>
          </Row>

          <AutoSizer disableHeight>
            {({ width }: any) => (
              <List
                height={500}
                itemCount={data.length}
                itemSize={() => 50} // Adjust the row height as needed
                width={width}
                itemData={{
                  data,
                  handleInputChange,
                  handleUserSkip,
                  packageOptions,
                }}
              >
                {RowRenderer}
              </List>
            )}
          </AutoSizer>
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
          className="mt-20 mb-20"
        >
          {data.length > 0 && (
            <MeshBillButton
              htmlType="button"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={handleUpload}
              text="Upload Users"
              disabled={isUploading}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default ImportUsersThroughFileTable;
