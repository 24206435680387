import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Dropdown, MenuProps, Row, Switch, Tag, Typography } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { getUserType } from "../../helpers/userDetails";
import "./../../styles/staff/profile.css";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";

import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import MeshBillButton from "../Items/Button/Index";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { MenuOutlined } from "@ant-design/icons";
import UpdateRouterModalComponent from "../Modals/UpdateRouterModal";
import UpdateNasStatusModalComponent from "../Modals/UpdateNasStatusModal";
import UpdateMikroNocProbeModal from "../Modals/UpdateMikroNocProbeModal";
import UpdateRouterRadiusPortsModal from "../Modals/UpdateRouterRadiusPortsModal";

// const UpdateNasStatusModalComponent = lazy(
//   () => import("../Modals/UpdateNasStatusModal")
// );
// const UpdateRouterRadiusPortsModal = lazy(
//   () => import("../Modals/UpdateRouterRadiusPortsModal")
// );

// const UpdateMikroNocProbeModal = lazy(
//   () => import("../Modals/UpdateMikroNocProbeModal")
// );

interface RouterSingleRowSimpleProp {
  label: string;
  value: string;
  isBorderBottom: boolean;
}
const RouterSingleRowSimple: React.FC<RouterSingleRowSimpleProp> = ({
  label,
  value,
  isBorderBottom = true,
}) => {
  return (
    <Col
      span={24}
      className={`profile-card-single-row ${
        isBorderBottom === true ? "border-bottom-1px" : ""
      }`}
    >
      <div className="values-container">
        <Typography className="user-label-primary">{label}</Typography>
        <Typography className="user-value"> {value}</Typography>
      </div>
    </Col>
  );
};
const ViewRouterDetailsComponent = () => {
  const { intPkNasDeviceID, intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetRouterDetailsApiProp>("superAdmin/getNasDeviceById");

  const [
    postRouterActivation,
    routerActivationResponse,
    routerActivationError,
    routerActivationLoading,
    routerActivationMessage,
    routerActivationErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>("superAdmin/activateNasDevice");

  const [showEnable, setShowEnable] = useState<boolean>(false);
  const [showUpdatePorts, setShowUpdatePorts] = useState<boolean>(false);
  const [showUpdateMikroNocProbe, setShowUpdateMikroNocProbe] =
    useState<boolean>(false);
  const [updateDetails, setUpdateDetails] = useState<boolean>(false);

  const [router, setRouter] = useState<SingleRouterDetails | null>(null);
  const [nasData, setNasData] = useState<EnableDisableRouterDataProp | null>(
    null
  );
  const role = getUserType();

  useEffect(() => {
    postApiData({
      intPkNasDeviceID: Number(intPkNasDeviceID),
      intPkCompanyID: Number(intPkCompanyID),
    });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setRouter(data || null);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowEnable(false);
    setNasData(null);
    setShowUpdatePorts(false);
    setShowUpdateMikroNocProbe(false);
    setUpdateDetails(false);
    if (isReload) {
      postApiData({
        intPkNasDeviceID: Number(intPkNasDeviceID),
        intPkCompanyID: Number(intPkCompanyID),
      });
    }
  };
  const handleRouterStatusChange = (
    intPkNasDeviceID: number,
    enable: number
  ) => {
    setNasData({ intPkNasDeviceID, enable });
    setShowEnable(true);
  };
  const activateRouter = () => {
    postRouterActivation({ intPkNasDeviceID: Number(intPkNasDeviceID) });
  };

  useEffect(() => {
    if (routerActivationResponse !== null) {
      const { message } = routerActivationResponse;
      if (message) {
        openNotification("success", "Success", message);
        postApiData({
          intPkNasDeviceID: Number(intPkNasDeviceID),
          intPkCompanyID: Number(intPkCompanyID),
        });
      }
    }
  }, [routerActivationResponse]);
  useEffect(() => {
    if (routerActivationError !== null && routerActivationErrorCount !== 0) {
      openNotification("error", "Error", routerActivationError);
    }
  }, [routerActivationError, routerActivationErrorCount]);
  if (apiLoading) {
    return <TailSpinLoader />;
  }
  const routerOptions: MenuProps["items"] = [
    {
      key: "UPDATE_DETAILS",
      label: (
        <Typography className="general-label-class">Update Details</Typography>
      ),
    },
    {
      key: "UPDATE_PORTS",
      label: (
        <Typography className="general-label-class">
          Update Radius Probe
        </Typography>
      ),
    },
    {
      key: "UPDATE_MIKRO_NOC_PROBE",
      label: (
        <Typography className="general-label-class">
          Update MikroNoc Probe
        </Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "UPDATE_PORTS":
        setShowUpdatePorts(true);
        break;
      case "UPDATE_MIKRO_NOC_PROBE":
        setShowUpdateMikroNocProbe(true);
        break;
      case "UPDATE_DETAILS":
        setUpdateDetails(true);
        break;
    }
  };
  const getProbeData = () => {
    if (!router) {
      return {
        intPkMeshBillRadiusProbeID: null,
        childProbeAccountingID: null,
        childProbeAuthID: null,
      };
    }
    let intPkMeshBillRadiusProbeID = null;
    let childProbeAccountingID = null;
    let childProbeAuthID = null;
    const childProbes = router.childPortsData;
    if (childProbes.length < 1) {
      return {
        intPkMeshBillRadiusProbeID,
        childProbeAccountingID,
        childProbeAuthID,
      };
    }
    const acctProbeObj = childProbes.find(
      (item) => item.portType === "ACCOUNTING"
    );
    if (acctProbeObj) {
      childProbeAccountingID = acctProbeObj.intPkMeshBillRadiusProbeChildID;
      intPkMeshBillRadiusProbeID = acctProbeObj.intPkMeshBillRadiusProbeID;
    }
    const authProbeObj = childProbes.find((item) => item.portType === "AUTH");
    if (authProbeObj) {
      childProbeAuthID = authProbeObj.intPkMeshBillRadiusProbeChildID;
      intPkMeshBillRadiusProbeID = authProbeObj.intPkMeshBillRadiusProbeID;
    }
    return {
      intPkMeshBillRadiusProbeID,
      childProbeAccountingID,
      childProbeAuthID,
    };
  };
  return (
    <>
      {contextHolder}
      {router && (
        <>
          {showEnable === true && nasData !== null && (
            <UpdateNasStatusModalComponent
              show={showEnable}
              handleClose={handleClose}
              data={nasData}
            />
          )}
          {updateDetails === true && router && (
            <UpdateRouterModalComponent
              show={updateDetails}
              handleClose={handleClose}
              router={router}
              intPkCompanyID={router.intPkCompanyID}
            />
          )}
          {showUpdateMikroNocProbe === true && (
            <UpdateMikroNocProbeModal
              show={showUpdateMikroNocProbe}
              handleClose={handleClose}
              intPkMikroNOCProbeID={router.intPkMikroNOCProbeID}
              intPkNasDeviceID={router.intPkNasDeviceID}
            />
          )}
          {showUpdatePorts === true && (
            <UpdateRouterRadiusPortsModal
              show={showUpdatePorts}
              handleClose={handleClose}
              intPkNasDeviceID={router.intPkNasDeviceID}
              {...getProbeData()}
            />
          )}
          <Row gutter={[16, 16]} className="full-width-row">
            <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
              <Row className="staff-profile-card-container">
                <Col
                  span={24}
                  className="staff-details-title-label-container border-bottom-1px"
                >
                  <Typography className="staff-profile-card-title">
                    Router Profile
                  </Typography>
                  <Dropdown
                    menu={{
                      items: routerOptions,
                      onClick: handleMenuClick,
                    }}
                    placement="bottomRight"
                  >
                    <MenuOutlined className="cursor-pointer" />
                  </Dropdown>
                </Col>
                <RouterSingleRowSimple
                  label="Router Name"
                  value={`${router.nasDeviceName}`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router IP"
                  value={`${router.nasDeviceIP}`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router Api UserName"
                  value={`${
                    router.nasDeviceApiUserName
                      ? router.nasDeviceApiUserName
                      : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router API Password"
                  value={`${
                    router.nasDeviceApiPassword
                      ? router.nasDeviceApiPassword
                      : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Router API PORT"
                  value={`${
                    router.nasDeviceApiPort ? router.nasDeviceApiPort : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="COA PORT"
                  value={`${router.coaPort ? router.coaPort : "---"}`}
                  isBorderBottom={true}
                />

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Authority Reachable
                    </Typography>
                    <Typography className="user-value">
                      {router.authorityStatus === null || false ? (
                        <Tag color="error">Un Reachable</Tag>
                      ) : (
                        <Tag color="success">Reachable</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      COA Enabled?
                    </Typography>
                    <Typography className="user-value">
                      {Number(router.isCoaEnabled) === 1 ? (
                        <Tag color="success">Enable</Tag>
                      ) : (
                        <Tag color="warning">Disabled</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      API Reachable?
                    </Typography>
                    <Typography className="user-value">
                      {router.apiStatus === null || false ? (
                        <Tag color="error">Un Reachable</Tag>
                      ) : (
                        <Tag color="success">Reachable</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      API Enabled?
                    </Typography>
                    <Typography className="user-value">
                      {Number(router.isApiEnabled) === 1 ? (
                        <Tag color="success">Enable</Tag>
                      ) : (
                        <Tag color="warning">Disabled</Tag>
                      )}
                    </Typography>
                  </div>
                </Col>

                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Status
                    </Typography>
                    <Typography className="user-value">
                      {router.isNasDevicePreTested === 1
                        ? "Activated"
                        : "Not Activated"}

                      {router.isNasDevicePreTested !== 1 ? (
                        <MeshBillButton
                          htmlType="button"
                          backgroundColor={ButtonProps.background.STANDARD}
                          height={ButtonProps.height.STANDARD}
                          width={`120px`}
                          textColor={ButtonProps.color.STANDARD}
                          onClick={() => {
                            console.log("clicked");
                            activateRouter();
                          }}
                          text="Activate"
                          disabled={routerActivationLoading}
                        />
                      ) : (
                        ""
                      )}
                    </Typography>
                  </div>
                </Col>
                <Col span={24} className={`profile-card-single-row `}>
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Enable
                    </Typography>
                    <Typography className="user-value">
                      <Switch
                        value={Boolean(router.enable)}
                        onChange={(e) => {
                          handleRouterStatusChange(
                            router.intPkNasDeviceID,
                            router.enable
                          );
                        }}
                      />
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xl={12} xxl={12} sm={24} md={12}>
              <Row className="staff-profile-card-container">
                <RouterSingleRowSimple
                  label="Router Type"
                  value={`${router.nasDeviceType}`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="MikroNoc Probe"
                  value={`${
                    router.mikroNocProbeIP ? router.mikroNocProbeIP : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Radius IP"
                  value={`${
                    router.childPortsData.length > 0
                      ? router.childPortsData[0].radiusProbeIP
                      : "---"
                  }`}
                  isBorderBottom={true}
                />
                <RouterSingleRowSimple
                  label="Auth Ports"
                  value={`${
                    router.childPortsData.length > 0
                      ? router.childPortsData
                          .filter((item) => item.portType === "AUTH")
                          .map((item) => {
                            return `${item.port}  `;
                          })
                      : "No Ports Assigned"
                  }`}
                  isBorderBottom={true}
                />

                <RouterSingleRowSimple
                  label="Accounting Port"
                  value={`${
                    router.childPortsData.length > 0
                      ? router.childPortsData
                          .filter((item) => item.portType === "ACCOUNTING")
                          .map((item) => {
                            return `${item.port} `;
                          })
                      : "No Ports Assigned"
                  }`}
                  isBorderBottom={true}
                />

                <RouterSingleRowSimple
                  label="Activation Date"
                  value={`${
                    router.isNasDevicePreTested === 1 &&
                    router.preTestSuccessDate
                      ? formatDateForJustMonthDayAndYear(
                          router.preTestSuccessDate
                        )
                      : "Not Activated"
                  }`}
                  isBorderBottom={true}
                />
                <Col
                  span={24}
                  className={`profile-card-single-row border-bottom-1px`}
                >
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Router Token
                    </Typography>
                    <Typography className="">
                      <CopyToClipboard
                        text={router.routerToken || ""}
                        onCopy={() => {}}
                      >
                        <MdContentCopy
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="cursor-pointer "
                        />
                      </CopyToClipboard>
                    </Typography>
                  </div>
                </Col>
                <Col span={24} className={`profile-card-single-row `}>
                  <div className="values-container">
                    <Typography className="user-label-primary">
                      Radius Secret
                    </Typography>
                    <Typography className="">
                      <CopyToClipboard
                        text={router.radiusSecret || ""}
                        onCopy={() => {}}
                      >
                        <MdContentCopy
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          className="cursor-pointer "
                        />
                      </CopyToClipboard>
                    </Typography>
                  </div>
                </Col>
                {/* 
                
                 <CopyToClipboard text={row.routerToken || ""} onCopy={() => {}}>
          <MdContentCopy
            onClick={(e) => {
              e.preventDefault();
            }}
            className="cursor-pointer "
          />
        </CopyToClipboard>
                */}
              </Row>
            </Col>
          </Row>{" "}
        </>
      )}
    </>
  );
};
export default ViewRouterDetailsComponent;
