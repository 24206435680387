import { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
// import "./index.css";
import useMultiNotification from "../../hooks/useNotification";
import useAxiosPost from "../../hooks/useAxiosPost";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";

const ChangePasswordComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postUpdateProfileDataApi,
    ProfileDataAApiResponse,
    ProfileDataAApiError,
    ProfileDataAApiLoading,
    ProfileDataAApiMessage,
    ProfileDataAApiErrorCount,
  ] = useAxiosPost<UpdateMyPasswordProp>("auth/updatePassword", true);
  useEffect(() => {
    if (ProfileDataAApiError !== null && ProfileDataAApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", ProfileDataAApiError);
    }
  }, [ProfileDataAApiError, ProfileDataAApiErrorCount]);
  useEffect(() => {
    if (ProfileDataAApiResponse !== null) {
      const { message } = ProfileDataAApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
      }
    }
  }, [ProfileDataAApiResponse]);
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { oldPassword, newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      openNotification(
        "error",
        "Validation Error",
        "New and confirm Password Mismatch"
      );
      return;
    }
    const dataToSend = {
      oldPassword,
      newPassword,
      confirmPassword,
    };
    postUpdateProfileDataApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]}>
        <Col offset={6} span={12} style={{ marginTop: "30px" }}>
          <Row style={{ width: "100%" }}>
            <Form
              name="updatePasswordForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              requiredMark={true}
              style={{ width: "100%" }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Old Password
                      </span>
                    }
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your old password",
                      },
                    ]}
                  >
                    <Input.Password
                      className="user-input-general-height"
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        New Password
                      </span>
                    }
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter new password",
                      },
                    ]}
                  >
                    <Input.Password className="user-input-general-height" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Retype New Password
                      </span>
                    }
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please retype new password",
                      },
                    ]}
                  >
                    <Input.Password className="user-input-general-height" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  span={24}
                >
                  <Form.Item>
                    <MeshBillButton
                      htmlType="submit"
                      backgroundColor={ButtonProps.background.STANDARD}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.STANDARD}
                      textColor={ButtonProps.color.STANDARD}
                      onClick={() => {
                        console.log("clicked");
                      }}
                      text="Update Password"
                      disabled={ProfileDataAApiLoading}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ChangePasswordComponent;
