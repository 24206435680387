import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { getUserType } from "../../helpers/userDetails";
import ViewRoutersTable from "../Tables/ViewRoutersTable";

// const ViewRoutersTable = lazy(() => import("../Tables/ViewRoutersTable"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));
// const CreateNewRouterModalComponent = lazy(
//   () => import("../Modals/CreateNewRouterModal")
// );
const ViewRoutersComponent = () => {
  const role = getUserType();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("routers/getNasDevicesByCompany");
  const [routers, setRouters] = useState<Router[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setRouters(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    if (isReload) {
      postApiData({});
    }
  };
  const handleRouterStatusChange = (
    intPkNasDeviceID: number,
    enable: number
  ) => {};
  return (
    <>
      {/* {showCreate === true && (
        <CreateNewRouterModalComponent
          show={showCreate}
          handleClose={handleClose}
        />
      )} */}
      <Row className="full-width-row">
        {/* <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowCreate(true);
            }}
            text="Create Router"
            icon={<FiPlusCircle />}
          />
        </Col> */}
        <Col span={24}>
          <ViewRoutersTable
            role={role}
            isLoading={apiLoading}
            routers={routers}
            handleRouterStatusChange={handleRouterStatusChange}
            companyCode={null}
            companyName={null}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewRoutersComponent;
