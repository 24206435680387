import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Tag, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./../../styles/tables.css";

import { useNavigate } from "react-router-dom";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import { getUserType } from "../../helpers/userDetails";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillPagination from "../Items/Pagination/MeshbillPagination";

// const TailSpinLoader = lazy(() => import("../Items/Loaders/TailSpinLoader"));
// const MeshBillPagination = lazy(
//   () => import("../Items/Pagination/MeshbillPagination")
// );
const ViewEndUserInvoicesTable: React.FC<EndUserInvoicesTableProp> = ({
  invoices,
  isLoading,
  pagination,
  handlePageSwitch,
  handleCollectPayment,
  handleDiscount,
  handleWaveOff,
  selectedDealer = null,
}) => {
  const navigate = useNavigate();
  const role = getUserType();
  const [activeRow, setActiveRow] = useState<EndUserInvoice | null>(null);

  const closedOptions: MenuProps["items"] = [
    {
      key: "VIEW_DETAILS",
      label: (
        <Typography className="general-label-class">View Details</Typography>
      ),
    },
  ];
  const openOptions: MenuProps["items"] = [
    {
      key: "COLLECT_PAYMENT",
      label: (
        <Typography className="general-label-class">Collect Payment</Typography>
      ),
    },
    {
      key: "VIEW_DETAILS",
      label: (
        <Typography className="general-label-class">View Details</Typography>
      ),
    },
  ];
  if (role === "ADMIN" || role === "DEALER") {
    openOptions.push(
      {
        key: "WAVE_OFF",
        label: (
          <Typography className="general-label-class">Wave Off</Typography>
        ),
      },
      {
        key: "DISCOUNT",
        label: (
          <Typography className="general-label-class">Discount</Typography>
        ),
      }
    );
  }

  const handleMenuClick = (e: any) => {
    if (!activeRow) {
      return;
    }
    if (selectedDealer) {
      return;
    }
    switch (e.key) {
      case "COLLECT_PAYMENT":
        handleCollectPayment(activeRow);
        break;
      case "WAVE_OFF":
        handleWaveOff(activeRow);
        break;
      case "DISCOUNT":
        handleDiscount(activeRow);
        break;
      case "VIEW_DETAILS":
        navigate(
          `/end-user-invoice-soa/${activeRow.intPkEndUserID}/${activeRow.intPkEndUserInvoiceID}`
        );
        break;
    }
  };

  const columns: TableProps<EndUserInvoice>["columns"] = [
    {
      title: "Title",
      render: (row) => <strong>{`${row.shortTitle}`}</strong>,
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      // key: "invoiceStatus",
      render: (text) => (
        <Tag>
          <strong
            className={
              text === "CLOSED"
                ? "balance-available-label"
                : "balance-minus-label"
            }
          >
            {" "}
            {`${text}`}
          </strong>
        </Tag>
      ),
    },
    {
      title: "Invoice Amount",
      render: (row) => <span>{`${row.invoiceTotalAmount}`}</span>,
    },

    {
      title: "Discount",
      render: (row) => (
        <span className="balance-minus-label">{`${row.invoiceDiscountAmount}`}</span>
      ),
    },
    {
      title: "Wave off",
      render: (row) => (
        <span className="balance-minus-label">{`${row.waveOfAmount}`}</span>
      ),
    },
    {
      title: "Receivable  Amount",
      render: (row) => (
        <span>{`${
          Number(row.invoiceTotalAmount) - Number(row.invoiceDiscountAmount)
        }`}</span>
      ),
    },
    {
      title: "Paid Amount",
      render: (row) => (
        <span className="balance-available-label">{`${row.invoicePaidAmount}`}</span>
      ),
    },
    {
      title: "Remaining Amount",
      render: (row) => <span>{`${row.invoiceRemainingAmount}`}</span>,
    },
    {
      title: "Created",
      dataIndex: "invoiceCreatedAt",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },

    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: row.invoiceStatus !== "CLOSED" ? openOptions : closedOptions,
            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="general-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="general-table-heading">
              User Invoices
            </Typography>
            <Table
              rowKey={`intPkEndUserInvoiceID`}
              className=" striped-table"
              columns={columns}
              dataSource={invoices}
              onRow={(record, rowIndex) => {
                return {};
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <MeshBillPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ViewEndUserInvoicesTable;
