import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ViewAllPPPoEUsersComponent from "./All";
import ViewExpiredUsersComponent from "./Expired";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import ViewOfflineUsersComponent from "./Offline";
import ViewExpiringUsersComponent from "./Expiring";
import ViewActivePPPoEUsersComponent from "./Active";
import ViewDisabledPPPoEUsersComponent from "./Disabled";
import useAxiosPost from "../../hooks/useAxiosPost";
import { getUserType } from "../../helpers/userDetails";
const tabsOptions = {
  all: "all",
  expiring: "expiring",
  expired: "expired",
  offline: "offline",
  active: "active",
  disabled: "disabled",
};
const ViewPPPoEUsersComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const _activeTab = (searchParams.get("activeTab") || "ALL").toLowerCase();
  const selectedDealer = (searchParams.get("selectedDealer") || "").toString();
  const [dealerId, setDealerId] = useState<string | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("dealers/getMyDealersMini");
  const [actualDealers, setActualDealers] = useState<any>([]);
  const [dealers, setDealers] = useState<MeshBillSelectOptions[]>([]);
  const [colSpan, setColSpan] = useState(18);
  const role = getUserType();
  useEffect(() => {
    console.log(`we are in it`, selectedDealer);
    if (selectedDealer === "") {
      setDealerId(null);
    } else {
      const found = actualDealers.find(
        (item: any) => item.staffUserName === selectedDealer
      );
      console.log({ found });
      if (!found) {
        setDealerId(null);
      } else {
        setDealerId(found.intPkHeadID);
      }
    }
  }, [selectedDealer]);
  useEffect(() => {
    if (role === "ADMIN") {
      postApiData({});
      setColSpan(12);
    }
  }, [role]);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setActualDealers(data || []);
      const _dealers = [{ label: "My Users", value: "" }];
      for (const dealer of data) {
        _dealers.push({
          label: dealer.staffUserName,
          value: dealer.staffUserName,
        });
      }
      setDealers(_dealers);
    }
  }, [apiResponse]);
  const getActiveComponent = (activeTab: string) => {
    switch (activeTab) {
      case tabsOptions.all:
        return (
          <ViewAllPPPoEUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
      case tabsOptions.expired:
        return (
          <ViewExpiredUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
      case tabsOptions.expiring:
        return (
          <ViewExpiringUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
      case tabsOptions.offline:
        return (
          <ViewOfflineUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
      case tabsOptions.active:
        return (
          <ViewActivePPPoEUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
      case tabsOptions.disabled:
        return (
          <ViewDisabledPPPoEUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
      default:
        return (
          <ViewAllPPPoEUsersComponent
            selectedDealer={selectedDealer ? selectedDealer : null}
          />
        );
    }
  };
  return (
    <>
      <Row gutter={16} className="full-width-row">
        {role === "ADMIN" && (
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "20px",
            }}
            lg={6}
            xl={6}
            xxl={6}
            md={12}
            sm={24}
            span={18}
          >
            <MeshBillSelect
              size="small"
              height="45px"
              value={selectedDealer}
              onChange={(e) => {
                const selectedOption = dealers.find(
                  (dealer) => dealer.value === e
                ); // Map the selected value back to the dealer object
                console.log(selectedOption);
                console.log(e);
                navigate(
                  `/pppoe-users?activeTab=${_activeTab}&selectedDealer=${e}`
                );
              }}
              showSearch={true}
              options={dealers}
            />
          </Col>
        )}
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
          }}
          lg={6}
          xl={6}
          xxl={6}
          md={12}
          sm={24}
          span={18}
        >
          <MeshBillSelect
            size="small"
            height="45px"
            value={_activeTab}
            onChange={(e) => {
              console.log(e);
              if (role === "ADMIN") {
                navigate(
                  `/pppoe-users?activeTab=${e}&selectedDealer=${selectedDealer}`
                );
              } else {
                navigate(`/pppoe-users?activeTab=${e}`);
              }
            }}
            options={[
              { label: "All Users", value: "all" },
              { label: "Active Users", value: "active" },
              { label: "Expiring Users", value: "expiring" },
              { label: "Expired Users", value: "expired" },
              { label: "Offline Users", value: "offline" },
              { label: "Disabled Users", value: "disabled" },
            ]}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          lg={colSpan}
          xl={colSpan}
          xxl={colSpan}
          md={colSpan}
          sm={24}
          span={colSpan}
        >
          <Link to={`/create-pppoe-user`}>
            <MeshBillButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {}}
              text="Create User"
              icon={<FiPlusCircle />}
            />
          </Link>
        </Col>
        <Col span={24}>{getActiveComponent(_activeTab)}</Col>
      </Row>
    </>
  );
};
export default ViewPPPoEUsersComponent;
