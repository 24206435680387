import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox, DatePicker } from "antd";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import Typography from "antd/es/typography/Typography";
import { MdEdit } from "react-icons/md";
import { calculateNextExpiryDate } from "../../helpers/date";
import moment from "moment";
import dayjs from "dayjs";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const VariableRechargeModalComponent: React.FC<RechargeModalProps> = ({
  show,
  handleClose,
  endUser,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<VariableRechargeApiProp>(
    "endUsers/variableRechargeEndUser",
    true
  );
  const [
    getVariableRechargeAmountApi,
    variableRechargeAmountApiResponse,
    variableRechargeAmountApiError,
    variableRechargeAmountApiLoading,
    variableRechargeAmountApiMessage,
    variableRechargeAmountApiErrorCount,
  ] = useAxiosPost<GetVariableRechargeAmountByExpiryDateApiProp>(
    "endUsers/getVariablePriceOfUserByExpiry"
  );
  const [amountAfterDiscount, setAmountAfterDiscount] = useState<number>(0);
  const [showInput, setShowInput] = useState<boolean>(false);
  const dateFormat = "YYYY-MM-DD";
  const nextExpiryDateBase = calculateNextExpiryDate(
    new Date(endUser.expiryDate),
    endUser.packageDuration
  );
  const defaultDate = moment(nextExpiryDateBase)
    .add(1, "days")
    .startOf("day")
    .format(dateFormat);
  useEffect(() => {
    getVariableRechargeAmountApi({
      expiryDate: defaultDate,
      intPkEndUserID: endUser.intPkEndUserID,
    });
  }, []);
  const onFinish = (values: any) => {
    const { amount, expiryDate } = values;
    console.log({ values });
    const expiryDateFormatted = expiryDate.format("YYYY-MM-DD");
    const dataToSend: RechargeEndUserApiProp = {
      amountPaid: showInput === true ? Number(amount) : amountAfterDiscount,
      intPkEndUserID: endUser.intPkEndUserID,
      expiryDate: expiryDateFormatted,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (variableRechargeAmountApiResponse !== null) {
      const data: any = variableRechargeAmountApiResponse.responseData;
      console.log({ data });
      const { totalAmount, amountAfterDiscount } = data;
      form.setFieldsValue({ amount: amountAfterDiscount });
      setAmountAfterDiscount(amountAfterDiscount);
    }
  }, [variableRechargeAmountApiResponse]);
  const disabledDate = (currentDate: any) => {
    const expiryDate = moment(endUser.expiryDate).startOf("day");
    const today = moment().startOf("day");

    if (expiryDate.isAfter(today)) {
      // Disable dates before the expiry date, including the expiry date
      return currentDate && currentDate <= expiryDate;
    } else {
      // Disable dates before today, including today
      return currentDate && currentDate <= today;
    }
  };
  return (
    <>
      <MeshBillModal
        title="Variable Recharge User"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        {variableRechargeAmountApiLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col
              span={24}
              className="meshbill-create-record-modal-form-container"
            >
              <div className="rechargeable-user-info">
                <Typography className="modal-user-name-label">
                  UserName: {endUser.username}
                </Typography>
                <Typography className="modal-user-name-label">
                  Package: {endUser.packageName}
                </Typography>
                <Typography className="modal-user-phone-label">
                  Phone Number : {endUser.phoneNumber}
                </Typography>
                <Typography className="modal-user-address-label">
                  {endUser.address}
                </Typography>
              </div>

              <div className="rechargeable-user-info mt-20 mb-20">
                <Typography>
                  <span className="balance-available-label">
                    {amountAfterDiscount}/-
                  </span>
                  <MdEdit
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInput(true);
                    }}
                    className="cursor-pointer"
                  />
                </Typography>
              </div>
              <Form
                //   requiredMark={true}
                style={{ width: "100%", marginTop: "50px" }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="variableRechargeUserForm"
                // initialValues={}
                form={form}
              >
                <Form.Item
                  label={
                    <span className="create-user-form-label">Expiry Date</span>
                  }
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Expiry Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%", height: "48px" }}
                    format={dateFormat}
                    defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                    onChange={(e) => {
                      if (e) {
                        const formattedDate = e.format(dateFormat);
                        getVariableRechargeAmountApi({
                          expiryDate: formattedDate,
                          intPkEndUserID: endUser.intPkEndUserID,
                        });
                      }
                    }}
                  />
                </Form.Item>
                {showInput === true && (
                  <Form.Item
                    name="amount"
                    rules={[
                      { required: true, message: "Please Enter Amount" },
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Amount must be greater than 0")
                              ),
                      },
                      {
                        validator: (_, value) =>
                          value > amountAfterDiscount
                            ? Promise.reject(
                                new Error(
                                  `Amount must not be greater than ${amountAfterDiscount}`
                                )
                              )
                            : Promise.resolve(),
                      },
                    ]}
                    label={
                      <span className="create-user-form-label">
                        Received Amount
                      </span>
                    }
                  >
                    <Input
                      type="number"
                      className="user-input-general-height"
                      placeholder="10"
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <MeshBillButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={`100%`}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {}}
                    htmlType="submit"
                    text="Recharge Now"
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </MeshBillModal>
    </>
  );
};
export default VariableRechargeModalComponent;
