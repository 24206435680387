import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./styles/Login.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "../../helpers/constants";
import {
  getCodeByUserName,
  pushNewCodeWithUserName,
} from "../../helpers/codes";
import MeshBillButton from "../Items/Button/Index";
const LoginComponent = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [code, setCode] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<LoginType>("auth/login");
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { username, password, code } = values;
    const dataToSend: LoginType = {
      username: username,
      password: password,
      code: code,
    };
    postLoginData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Login Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      const responseData: any = loginResponseData.responseData;
      console.log({ responseData });
      const { token, role, isProfileCompleted } = responseData;
      pushNewCodeWithUserName(form.getFieldValue("username"), code);
      localStorage.setItem("auth_token", token || "");
      localStorage.setItem("userType", role || "");
      localStorage.setItem("isProfileCompleted", isProfileCompleted);
      window.location.href = "/";
    }
  }, [loginResponseData]);
  useEffect(() => {
    if (!username) {
      const inputElement = document.getElementById(
        "username-input"
      ) as HTMLInputElement;

      if (inputElement) {
        setUsername(inputElement.value);
      }
    }
    console.log({ username });
    if (username) {
      //lets find out if there's a code associated
      const code = getCodeByUserName(username);
      if (code !== "") {
        setCode(code);
        form.setFieldsValue({ code: code });
      }
    }
  }, [username]);

  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <Row gutter={[16, 1]} style={{ height: "100%" }}>
            <Col span={24}>
              <div className="logo-login-form">
                {/* <img
                  style={{ width: "300px", height: "100px" }}
                  src={LogoImage}
                  alt="Logo Image"
                /> */}
                <Typography className="meshbill-basic-login-logo">
                  MeshBill Basic
                </Typography>
              </div>

              <Form
                className="login-form"
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                requiredMark={true}
                form={form}
              >
                <Form.Item
                  label={
                    <span className="create-user-form-label">Company Code</span>
                  }
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Company Code",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input
                    style={{ height: "60px" }}
                    placeholder="Enter your company code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="create-user-form-label">UserName</span>
                  }
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Username",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input
                    style={{ height: "60px" }}
                    placeholder="Enter your Username"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Password</span>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Password",
                    },
                    {
                      min: 6,
                      message: "Minimum Characters for password are 6",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <Input.Password
                    style={{ height: "60px" }}
                    className="login-form-input-item"
                    placeholder="Enter your Password"
                  />
                </Form.Item>
                {/* <div className="remember-me-forgot-password-container">
                  <div className="forgot-password-container">
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/forgot-password");
                      }}
                      className="forgot-password-label"
                    >
                      Forgot Password
                    </span>
                  </div>
                </div> */}
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={24}
                  >
                    <Form.Item>
                      <MeshBillButton
                        htmlType="submit"
                        backgroundColor={ButtonProps.background.STANDARD}
                        height={ButtonProps.height.STANDARD}
                        width={ButtonProps.width.STANDARD}
                        textColor={ButtonProps.color.STANDARD}
                        onClick={() => {}}
                        text="Login"
                        disabled={loginLoading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default LoginComponent;
