import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import "./../../styles/tables.css";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import { render } from "@testing-library/react";
const CreateDealersAtImportTimeTable: React.FC<
  CreateDealersAtImportTimeTableProp
> = ({ dealers, handleCreateDealers }) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [data, setData] = useState<BulkDealerRecord[]>([]);
  useEffect(() => {
    setData(dealers);
  }, [dealers]);

  const handleInputChange = (value: string, key: string, index: number) => {
    console.log({ value, key, index });
    const newData = [...data];
    newData[index] = {
      ...newData[index],
      [key]: value,
    };
    console.log({ newData });
    setData(newData);
  };
  const columns = [
    {
      title: "Ready?",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Checkbox
          disabled={row.isAlreadyCreated}
          checked={Boolean(row.isReadyToCreate)}
          onChange={(e: any) =>
            handleInputChange(e.target.checked, "isReadyToCreate", index)
          }
        >
          {row.isAlreadyCreated === true ? "Already Created" : "Create"}
        </Checkbox>
      ),
    },
    {
      title: "First Name",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.firstName || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "firstName", index)
          }
        />
      ),
    },

    {
      title: "Last Name",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.lastName || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "lastName", index)
          }
        />
      ),
    },
    {
      title: "User Name",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.staffUserName || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "staffUserName", index)
          }
        />
      ),
    },
    {
      title: "Address",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.address || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "address", index)
          }
        />
      ),
    },
    {
      title: "Password",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.password || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "password", index)
          }
        />
      ),
    },

    {
      title: "Phone",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.phoneNumber || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "phoneNumber", index)
          }
        />
      ),
    },

    {
      title: "Email",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          value={row.email || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "email", index)
          }
        />
      ),
    },
    {
      title: "Credit Allowed",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Checkbox
          disabled={row.isAlreadyCreated || !row.isReadyToCreate}
          checked={Boolean(row.isCreditAllowed)}
          onChange={(e: any) =>
            handleInputChange(e.target.checked, "isCreditAllowed", index)
          }
        ></Checkbox>
      ),
    },
    {
      title: "Credit Limit",
      render: (row: BulkDealerRecord, _: any, index: number) => (
        <Input
          disabled={
            row.isAlreadyCreated || !row.isCreditAllowed || !row.isReadyToCreate
          }
          value={row.creditLimit || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e.target.value, "creditLimit", index)
          }
        />
      ),
    },
  ];
  const validateData = (data: BulkDealerRecord[]): BulkDealerRecord[] => {
    // Return users who do not have a selected package
    return data.filter((row) => {
      if (row.isAlreadyCreated === true) return true;
      if (!row.isReadyToCreate === true) return true;
      return row.firstName && row.lastName && row.staffUserName && row.password;
    });
  };
  const handleUpload = () => {
    const validData = validateData(data);
    console.log({ validData, data });
    if (validData.length < data.length) {
      openNotification(
        `error`,
        "Operation Failed",
        "Please fill in all required fields for each dealer."
      );
    } else {
      const dataToUpload = validData.filter(
        (item) => item.isReadyToCreate || item.isAlreadyCreated
      );
      if (dataToUpload.length < 1) {
        openNotification(
          `error`,
          "Operation Failed",
          "Please select At least one dealer to create"
        );
        // Proceed with upload
      }
      handleCreateDealers(dataToUpload);
    }
  };
  // const handleUserSkip = (username: string) => {
  //   const updatedData = data.filter((user) => user.username !== username);
  //   setData(updatedData);
  // };
  return (
    <div className="general-table-container">
      {contextHolder}
      <Row>
        <Col span={24}>
          <Typography className="general-table-heading">Dealers</Typography>
          <Table
            scroll={{ x: 1300, y: 500 }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
          className="mt-20 mb-20"
        >
          {data.length > 0 && (
            <MeshBillButton
              htmlType="button"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={handleUpload}
              text="Create Dealers"
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default CreateDealersAtImportTimeTable;
