import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./../../styles/staff/profile.css";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import MeshBillButton from "../Items/Button/Index";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import ViewMyProfileCardProp from "./ViewProfileCard";
import UpdateStaffDetailsModalComponent from "../Modals/UpdateStaffDetailsModal";
import ViewStaffTransactionsTable from "../Tables/ViewStaffTransactionsTable";

const ViewProfileComponent = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [transactionsPagination, setTransactionsPagination] =
    useState<PaginationProp>({
      totalPages: 0,
      currentPage: 1,
      previousPage: null,
      nextPage: null,
      totalCount: 0,
      pageSize: 50,
    });
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyProfile");
  const [
    getStaffTransactionsApi,
    staffTransactionsApiResponse,
    staffTransactionsApiError,
    staffTransactionsApiLoading,
    staffTransactionsApiMessage,
    staffTransactionsApiErrorCount,
  ] = useAxiosPost<ResponseProp>("staffTransactions/getMyTransactions");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
    getStaffTransactionsApi({});
  }, []);
  const handleClose = (isReload: boolean) => {
    setShowUpdate(false);

    if (isReload) {
      postApiData({});
    }
  };
  useEffect(() => {
    if (staffTransactionsApiResponse !== null) {
      const data: any = staffTransactionsApiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        transactions,
      } = data;
      setTransactionsPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setTransactions(transactions || []);
    }
  }, [staffTransactionsApiResponse]);

  if (apiLoading || staffTransactionsApiLoading) {
    return <TailSpinLoader />;
  }
  return (
    <>
      {showUpdate === true && staff !== null && (
        <UpdateStaffDetailsModalComponent
          activeStaff={staff}
          show={showUpdate}
          handleClose={handleClose}
          apiUrl="updateProfile"
        />
      )}
      {contextHolder}
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowUpdate(true);
            }}
            text="Update Profile"
          />
        </Col>
        <Col span={24}>{staff && <ViewMyProfileCardProp staff={staff} />}</Col>
        {staff && (
          <Col className="mt-20" span={24}>
            <ViewStaffTransactionsTable
              tableTitle="My Transactions"
              isLoading={staffTransactionsApiLoading}
              transactions={transactions}
              role={staff.staffRole}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
export default ViewProfileComponent;
