import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox } from "antd";
import { ButtonProps, getWalletDays } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
// const MeshBillSelect = lazy(() => import("../Items/Select/Index"));
// const MeshBillModal = lazy(() => import("../Items/Modal/Index"));
// const MeshBillButton = lazy(() => import("../Items/Button/Index"));

const CreateStaffModalComponent: React.FC<CreateStaffModalProps> = ({
  show,
  handleClose,
  parentRole,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateNewStaffApiProp>(
    `auth/${
      parentRole === "DEALER" ? "createNewDealerRman" : "createNewStaff"
    }`,
    true
  );

  const onFinish = (values: any) => {
    const { phoneNumber, email } = values;
    // if (!email && !phoneNumber) {
    //   openNotification("error", "Error", `Either Enter Phone or Email`);
    //   return;
    // }
    const dataToSend: CreateNewStaffApiProp = {
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      address: values.address,
      walletDays: Number(values.walletDays),
      walletLimit: Number(values.walletLimit),
      email: email ? email : null,
      phoneNumber: phoneNumber ? phoneNumber : null,
      designation: parentRole === "DEALER" ? "DEALER_RMAN" : "RMAN",
      staffUserName: values.staffUserName,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <MeshBillModal
        title="Create Staff"
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            <Form
              //   requiredMark={true}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="createNewStaffForm"
              form={form}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Deo"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Phone Number",
                //   },
                // ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">Email</span>}
                name="email"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Phone Number",
                //   },
                // ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="john@email.com"
                />
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="staffUserName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Staff UserName",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="johndoe1"
                />
              </Form.Item>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter Staff Password",
                  },
                  {
                    min: 6,
                    message: "Minimum 6 Characters Are required for password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="password"
                />
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Wallet Limit</span>
                }
                name="walletLimit"
                rules={[
                  {
                    required: true,
                    message: "Please enter Wallet Limit",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Wallet Limit Must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="50000"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Wallet Days</span>
                }
                name="walletDays"
                rules={[
                  {
                    required: true,
                    message: "Please enter Wallet Days",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Wallet Days Must be greater than 0")
                          ),
                  },
                ]}
              >
                <MeshBillSelect
                  size="large"
                  height="45px"
                  options={getWalletDays()}
                />
              </Form.Item>
              <Form.Item>
                <MeshBillButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Create Staff"
                  disabled={apiLoading}
                  icon={<FiPlusCircle />}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default CreateStaffModalComponent;
