import { useEffect, useState } from "react";
import useAxiosPost, { authTokenString } from "../../hooks/useAxiosPost";
import { Col, Row, Typography } from "antd";
import MeshBillSelect from "../Items/Select/Index";
import MeshBillButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import { socket } from "../../socketService";
import { useNavigate } from "react-router-dom";
import ImportSecretsTable from "../Tables/ImportSecretsTable";
import moment from "moment";

const ImportMikrotikSecrets = () => {
  const dateFormat = "YYYY-MM-DD";

  const navigate = useNavigate();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>(
    "mikronoc/getSecretsFromRouterForSync"
  );
  const { openNotification, contextHolder } = useMultiNotification();
  const [getPackagesApi, packagesApiResponse] = useAxiosPost<ResponseProp>(
    "packages/getPackagesByOwner"
  );

  const [
    requestToGetConnectedUsersApi,
    requestToGetConnectedUsersApiResponse,
    requestToGetConnectedUsersApiError,
    requestToGetConnectedUsersApiLoading,
    requestToGetConnectedUsersApiMessage,
    requestToGetConnectedUsersApiErrorCount,
  ] = useAxiosPost<ActivateRouterApiProp>(
    "mikronoc/syncSecretsFromRouterRequest"
  );

  const [routersApi, routersApiResponse] = useAxiosPost<ResponseProp>(
    "routers/getNasDevicesByCompany"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [routers, setRouters] = useState<MeshBillSelectOptions[]>([]);
  const [selectedRouter, setSelectedRouter] = useState<any>();
  const [secrets, setSecrets] = useState<SecretFromRouter[]>([]);
  const [packages, setPackages] = useState<SinglePackage[]>([]);
  const [packageOptions, setPackageOptions] = useState<MeshBillSelectOptions[]>(
    []
  );
  const [totalUsersFetched, setTotalUsersFetched] = useState<number>(0);
  const [newUsers, setNewUsers] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  useEffect(() => {
    routersApi({});
    getPackagesApi({});
    socket.emit(
      "join",
      {
        userName: localStorage.getItem("username") || "client",
        client: "web",
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;

      const packageOptions: MeshBillSelectOptions[] = data.map(
        (item: any): MeshBillSelectOptions => {
          return {
            value: item.intPkPackagePriceID,
            label: item.packageName,
          };
        }
      );
      setPackages(data || []);
      setPackageOptions(packageOptions);
    }
  }, [packagesApiResponse]);
  useEffect(() => {
    if (apiResponse !== null) {
      const responseData: any = apiResponse.responseData;
      const { data = [] } = responseData;
      if (secrets.length < 1) {
        const packagesObjectified: any = {};
        for (const _package of packages) {
          if (_package.profileName) {
            packagesObjectified[_package.profileName] = _package;
          }
        }
        const defaultDate = moment()
          .add(1, "month")
          .startOf("day")
          .format(dateFormat);

        const _data = data.map((item: any) => {
          const found = packagesObjectified[item.profileName];
          return {
            ...item,
            isReadyToCreate: false,
            intPkPackagePriceID: found ? found.intPkPackagePriceID : "",
            expiryDate: defaultDate,
          };
        });
        setSecrets(_data);
        setIsLoading(false);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    if (routersApiResponse !== null) {
      const data: any = routersApiResponse.responseData || [];
      const _routers = data.map((item: any): MeshBillSelectOptions => {
        return {
          value: item.intPkNasDeviceID,
          label: item.nasDeviceName,
        };
      });
      setRouters(_routers);
    }
  }, [routersApiResponse]);
  useEffect(() => {
    if (
      requestToGetConnectedUsersApiError !== null &&
      requestToGetConnectedUsersApiErrorCount !== 0
    ) {
      openNotification(
        `error`,
        "Login Failed",
        requestToGetConnectedUsersApiError
      );
    }
  }, [
    requestToGetConnectedUsersApiError,
    requestToGetConnectedUsersApiErrorCount,
  ]);

  useEffect(() => {
    if (requestToGetConnectedUsersApiResponse !== null) {
      const data: any = requestToGetConnectedUsersApiResponse.responseData;
      startIntervalHandler();
    }
  }, [requestToGetConnectedUsersApiResponse]);
  const startIntervalHandler = () => {
    const interval = setInterval(() => {
      postApiData({ intPkNasDeviceID: Number(selectedRouter) });
    }, 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, 30000);
  };
  const handleUploadUsers = (users: SecretFromRouter[]) => {
    console.log({ users });
    setIsUploading(true);
    socket.emit("uploadBulkUsersData", {
      users: users,
      authToken: authTokenString,
    });
  };
  useEffect(() => {
    socket.on(`errorUploadingUsers`, (data) => {
      console.log({ data });
      openNotification(
        `error`,
        "Operation Failed",
        data.message || "Error While Uploading"
      );
      setIsUploading(false);
    });
    socket.on(`bulkUserUploaded`, (data) => {
      console.log({ data });
      openNotification(
        `success`,
        "Success",
        data.message || "Users Uploaded Successfully"
      );
      setTimeout(() => {
        navigate("/");
      }, 2500);
    });
  }, []);
  useEffect(() => {
    setTotalUsersFetched(secrets.length);
    setNewUsers(
      secrets.filter((item) => item.isAlreadyCreated !== true).length
    );
  }, [secrets]);

  return (
    <>
      {contextHolder}

      <Row className="full-width-row mb-20">
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            alignItems: "center",
          }}
          span={8}
        >
          <MeshBillSelect
            size="large"
            height="45px"
            value={selectedRouter}
            onChange={(e) => setSelectedRouter(e)}
            options={routers}
          />
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={`200px`}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setSecrets([]);
              if (selectedRouter) {
                requestToGetConnectedUsersApi({
                  intPkNasDeviceID: Number(selectedRouter),
                });
                setIsLoading(true);
              }
            }}
            // disabled={!selectedRouter || isDisabled}
            text="Fetch Users"
          />
        </Col>
      </Row>

      <Row className="full-width-row mb-20 mt-20">
        {selectedRouter && (
          <div>
            <Typography>Total Users : {totalUsersFetched}</Typography>
            <Typography>New Users : {newUsers}</Typography>
          </div>
        )}
      </Row>
      <Row className="full-width-row ">
        {selectedRouter && (
          <Col span={24}>
            <ImportSecretsTable
              secrets={secrets}
              isLoading={isLoading}
              packageOptions={packageOptions}
              handleUploadUsers={handleUploadUsers}
              isUploading={isUploading}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
export default ImportMikrotikSecrets;
