import { useContext, useEffect, lazy } from "react";
import TitleContext from "../context/TitleContext";
import CompanyGlobalSettingsComponent from "../components/auth/CompanyGlobalSettings";

const GlobalSettingsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Global Settings");
  }, [setTitle]);
  return (
    <>
      <CompanyGlobalSettingsComponent />
    </>
  );
};
export default GlobalSettingsPage;
