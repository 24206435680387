import { useEffect, useState } from "react";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Col, Row } from "antd";
import { ButtonProps } from "../../helpers/constants";
import { FiPlusCircle } from "react-icons/fi";
import { useParams, useSearchParams } from "react-router-dom";
import { getUserType } from "../../helpers/userDetails";
import CreateNewRouterModalComponent from "../Modals/CreateNewRouterModal";
import UpdateNasStatusModalComponent from "../Modals/UpdateNasStatusModal";
import MeshBillButton from "../Items/Button/Index";
import ViewRoutersTable from "../Tables/ViewRoutersTable";

const ViewRoutersComponent = () => {
  const { intPkCompanyID } = useParams();
  const [searchParams] = useSearchParams();
  const companyName = searchParams.get("companyName") || "";
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleCompanyDataApiProp>("superAdmin/getRouterByCompany");
  const [routers, setRouters] = useState<Router[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showEnable, setShowEnable] = useState<boolean>(false);
  const [nasData, setNasData] = useState<EnableDisableRouterDataProp | null>(
    null
  );
  const role = getUserType();

  useEffect(() => {
    postApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setRouters(data || []);
    }
  }, [apiResponse]);
  const handleClose = (isReload: boolean) => {
    setShowCreate(false);
    setShowEnable(false);
    setNasData(null);
    if (isReload) {
      postApiData({ intPkCompanyID: Number(intPkCompanyID) });
    }
  };
  const handleRouterStatusChange = (
    intPkNasDeviceID: number,
    enable: number
  ) => {
    setNasData({ intPkNasDeviceID, enable });
    setShowEnable(true);
  };
  return (
    <>
      {showCreate === true && (
        <CreateNewRouterModalComponent
          show={showCreate}
          handleClose={handleClose}
          intPkCompanyID={Number(intPkCompanyID)}
        />
      )}
      {showEnable === true && nasData !== null && (
        <UpdateNasStatusModalComponent
          show={showEnable}
          handleClose={handleClose}
          data={nasData}
        />
      )}
      <Row className="full-width-row">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          span={24}
        >
          <MeshBillButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShowCreate(true);
            }}
            text="Create Router"
            icon={<FiPlusCircle />}
          />
        </Col>
        <Col span={24}>
          <ViewRoutersTable
            role={role}
            isLoading={apiLoading}
            routers={routers}
            handleRouterStatusChange={handleRouterStatusChange}
            companyName={companyName}
            companyCode={Number(intPkCompanyID)}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewRoutersComponent;
