import { useEffect, useState } from "react";
import { Row } from "antd";
import useMultiNotification from "../../../hooks/useNotification";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserType } from "../../../helpers/userDetails";
import ViewUserAAALogsComponent from "./UserAAALogs";
import ViewUserCreditHistoryComponent from "./UserCreditHistory";
import ViewUserInvoicesComponent from "./UserInvoices";
import ViewUserProfileComponent from "./UserProfile";
import MeshBillTabs from "../../Items/Tabs";

// const MeshBillTabs = lazy(() => import("../../Items/Tabs"));
// const ViewUserProfileComponent = lazy(() => import("./UserProfile"));
// const ViewUserInvoicesComponent = lazy(() => import("./UserInvoices"));
// const ViewUserAAALogsComponent = lazy(() => import("./UserAAALogs"));
// const UserCreditHistoryComponent = lazy(() => import("./UserCreditHistory"));
const UserProfileComponent = () => {
  const role = getUserType();
  const navigate = useNavigate();
  const { intPkEndUserID } = useParams();
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const fullName = searchParams.get("fullName");
  const selectedDealer = searchParams.get("selectedDealer") || null;

  const { openNotification, contextHolder } = useMultiNotification();

  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const [tabs, setTabs] = useState<MeshBillTabItemsProp[]>(
    role === "ADMIN"
      ? [
          {
            text: "Profile",
            isActive: true,
            id: 1,
          },

          {
            text: "Invoices",
            isActive: false,
            id: 2,
          },
          {
            text: "Session Logs",
            isActive: false,
            id: 3,
          },
          {
            text: "Credit History",
            isActive: false,
            id: 4,
          },
        ]
      : [
          {
            text: "Profile",
            isActive: true,
            id: 1,
          },

          {
            text: "Invoices",
            isActive: false,
            id: 2,
          },
          {
            text: "Credit History",
            isActive: false,
            id: 4,
          },
        ]
  );

  const handleTabSwitch = (tabId: number) => {
    if (role === "ADMIN" && selectedDealer !== null) {
      navigate(
        `/pppoe-user-details/${intPkEndUserID}?activeTab=${tabId}&fullName=${fullName}&selectedDealer=${selectedDealer}`
      );
    } else {
      navigate(
        `/pppoe-user-details/${intPkEndUserID}?activeTab=${tabId}&fullName=${fullName}`
      );
    }
  };

  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return (
          <ViewUserProfileComponent
            intPkEndUserID={Number(intPkEndUserID)}
            selectedDealer={selectedDealer}
          />
        );
      case 2:
        return (
          <ViewUserInvoicesComponent
            intPkEndUserID={Number(intPkEndUserID)}
            selectedDealer={selectedDealer}
          />
        );
      case 3:
        return (
          <ViewUserAAALogsComponent
            intPkEndUserID={Number(intPkEndUserID)}
            selectedDealer={selectedDealer}
          />
        );
      case 4:
        return (
          <ViewUserCreditHistoryComponent
            intPkEndUserID={Number(intPkEndUserID)}
            selectedDealer={selectedDealer}
          />
        );
      default:
        return (
          <ViewUserProfileComponent
            intPkEndUserID={Number(intPkEndUserID)}
            selectedDealer={selectedDealer}
          />
        );
    }
  };
  return (
    <>
      {contextHolder}
      <Row className="full-width-row">
        <MeshBillTabs
          colSpan={role === "ADMIN" ? 6 : 8}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row className="full-width-row" gutter={[16, 16]}>
        {showActiveComponent(activeTab)}
      </Row>
    </>
  );
};
export default UserProfileComponent;
