export const baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:6101/api/v1";
const ENVIRONMENT = process.env.FE_ENV || "STAGE";
export const getBaseApiUrl = () => {
  if (ENVIRONMENT === "REMOTE_LOCAL") {
    return (
      process.env.REACT_APP_API_URL_LOCAL_REMOTE ||
      "http://10.100.210.111:7011/api/v1"
    );
  }
  if (ENVIRONMENT === "LOCAL") {
    return (
      process.env.REACT_APP_API_URL_LOCAL || "http://localhost:7011/api/v1"
    );
  }
  if (ENVIRONMENT === "STAGE") {
    return (
      process.env.REACT_APP_API_URL_STAGE ||
      "https://api.stage.meshbill.com/api/v1"
    );
  }
  return "https://api.stage.meshbill.com/api/v1";
};

export const getSocketURl = () => {
  if (ENVIRONMENT === "REMOTE_LOCAL") {
    return (
      process.env.REACT_APP_API_URL_LOCAL_REMOTE ||
      "http://10.100.210.111:7011/api/v1"
    );
  }
  if (ENVIRONMENT === "LOCAL") {
    process.env.REACT_APP_API_URL_LOCAL || "http://localhost:7011/api/v1";
  }
  if (ENVIRONMENT === "STAGE") {
    process.env.REACT_APP_API_URL_STAGE ||
      "https://api.stage.meshbill.com/api/v1";
  }
  return "https://api.stage.meshbill.com/api/v1";
};
