import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Checkbox, Typography } from "antd";
import { ButtonProps, getWalletDays } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./../../styles/modal.css";
import { FiPlusCircle } from "react-icons/fi";
import MeshBillModal from "../Items/Modal/Index";
import MeshBillButton from "../Items/Button/Index";

const ViewEndUserIdCardDetailsModal: React.FC<EndUserUpdateModalProps> = ({
  show,
  handleClose,
  endUser,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [backImage, setBackImage] = useState<string | null>(null);
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [postFrontApi, frontApiResponse] =
    useAxiosPost<ViewEncryptedImageApiProp>(
      "cloudStorage/decryptionServiceApi"
    );
  const [postBackApi, backApiResponse] =
    useAxiosPost<ViewEncryptedImageApiProp>(
      "cloudStorage/decryptionServiceApi"
    );
  useEffect(() => {
    if (
      endUser.id_back_intPkResourceFileID &&
      endUser.id_front_intPkResourceFileID
    ) {
      postFrontApi({
        intPkResourceFileID: endUser.id_front_intPkResourceFileID,
      });
      postBackApi({
        intPkResourceFileID: endUser.id_back_intPkResourceFileID,
      });
    }
  }, []);

  useEffect(() => {
    if (frontApiResponse !== null) {
      const data: any = frontApiResponse.responseData;
      setFrontImage(data);
    }
  }, [frontApiResponse]);
  useEffect(() => {
    if (backApiResponse !== null) {
      const data: any = backApiResponse.responseData;
      setBackImage(data);
    }
  }, [backApiResponse]);

  return (
    <>
      <MeshBillModal
        title={`ID Card Details `}
        headerStyle={{
          backgroundColor: "var(--primary-background-color)",
          color: "var(--primary-text-color)",
          fontSize: "16px",
          fontWeight: "var(--fw-700)",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col
            span={24}
            className="meshbill-create-record-modal-form-container"
          >
            {frontImage !== null && (
              <>
                <Typography
                  style={{ textAlign: "center", width: "100%" }}
                  className="general-input-label"
                >
                  Front
                </Typography>
                <img
                  style={{
                    width: "90%",
                    margin: "20px",
                    border: "1px solid #E2DFFF",
                  }}
                  src={`data:image/jpeg;base64,${frontImage}`}
                />
              </>
            )}
            {backImage !== null && (
              <>
                <Typography
                  style={{ textAlign: "center", width: "100%" }}
                  className="general-input-label"
                >
                  Back
                </Typography>
                <img
                  style={{
                    width: "90%",
                    margin: "20px",
                    border: "1px solid #E2DFFF",
                  }}
                  src={`data:image/jpeg;base64,${backImage}`}
                />
              </>
            )}
          </Col>
        </Row>
      </MeshBillModal>
    </>
  );
};
export default ViewEndUserIdCardDetailsModal;
